import TextHeader from '../../components/textHeader/TextHeader';

import { trade } from '../../api/tempData';

import responsibilityImage from '../../assets/responsibiltyCover.png';

import MailingListSignup from '../../components/shared/mailing/Mailing';
import CompanyGrid from '../../components/shared/grid/CompanyGrid';
import CompanyBanner from '../../components/shared/grid/CompanyBanner';

const ResponsibilityPage = () => {
  return (
    <>
      <CompanyBanner
        imageData={responsibilityImage}
        hasTitle={true}
        title="Responsibility"
      />

      <TextHeader
        heading="Our View on Responsibilities"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                "
        isCentered={false}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />

      <CompanyGrid
        marginTop={15}
        marginBottom={15}
        image={trade}
        isReverse={true}
        heading="Are you in the trade?"
        hasButtons={true}
        buttonTitle="LEARN MORE"
        buttonTitleTwo="LOGIN"
      />

      <MailingListSignup />
    </>
  );
};

export default ResponsibilityPage;
