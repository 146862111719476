import { useEffect } from 'react';
import TextHeader from '../../components/textHeader/TextHeader';
import { useLocation } from "react-router-dom";
import { captureAnalytics } from '../../utilities/Analytics';



const WallPaperCalculator = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();

    useEffect(() => {
        captureAnalytics('pageview', location.pathname, 'Wallpaper Calculator');
      }, [location.pathname]);

    return (
        <>
            <TextHeader
                heading={'Wallpaper Calculator'}
                content={
                    'Please use the calculator below to measure out your requirements:'
                }
                isCentered={false}
                width='70%'
                marginTop={10}
                marginBottom={5}
            />

            <iframe
                src='data/wallpaper_calculator.html'
                style={{
                    width: '100%',
                    height: '170vh',
                    border: 'none',
                    marginBottom: '2rem',
                }}
                title='wallpaper-calculator'
            />
        </>
    );
};

export default WallPaperCalculator;
