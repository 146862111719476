import { useState } from 'react';
import { useForm } from 'react-hook-form';
import DocumentUploads from '../../../components/shared/DocumentUploads/DocumentUploads';

import { Grid, Box, Typography, Paper, Stack, Button } from '@mui/material';

function DocumentUpload({
  updateStep,
  previousStep,
  formData,
  setFormData,
  step,
}: any) {
  const [directorsFileData, setDirectorsFileData] = useState();
  const [companyRegData, setCompanyRegData] = useState();
  const [bankFileData, setBankFileData] = useState();
  const [addressFileData, setAddressFileData] = useState();

  const { handleSubmit } = useForm<any>();

  const formSubmit = handleSubmit((data, e) => {
    e?.preventDefault();

    const dataStructure = [
      { directors: directorsFileData },
      { company_registration: companyRegData },
      { bank_letter: bankFileData },
      { address: addressFileData },
    ];

    setFormData({
      ...formData,
      documentation: dataStructure,
    });

    updateStep();
  });

  return (
    <Grid mt={9}>
      <Paper
        sx={{
          display: { xs: 'flex', lg: 'none' },
          flexDirection: 'column',
          p: 3,
          gap: 5,
        }}
      >
        <Typography variant="caption">
          Please supply the following required documentation
        </Typography>
      </Paper>
      <Typography
        sx={{
          display: { xs: 'none', lg: 'flex' },
          fontSize: '32px',
          fontWeight: 'bold',
          lineHeight: '33px',
          textTransform: 'capitalize',
          color: '#000',
        }}
      >
        Documentation
      </Typography>
      <Typography
        sx={{
          display: { xs: 'none', lg: 'flex' },
          fontSize: '16px',
          fontWeight: 'bold',
          lineHeight: '20px',
          color: '#000',
          mt: '30px',
        }}
      >
        Please supply the following required documentation
      </Typography>
      {/* 1 */}
      <form onSubmit={formSubmit}>
        <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
          <Stack>
            <Typography
              variant="caption"
              sx={{
                fontSize: '18px',
                color: '#000',
                fontWeight: { xs: '700', lg: '500' },
              }}
            >
              1.*Directors/Owners ID Documents
            </Typography>
            <Typography variant="caption">
              Please ensure to include all directors identity documents
            </Typography>
            <DocumentUploads setFileData={setDirectorsFileData} />
          </Stack>
        </Paper>

        {/* 2 */}
        <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
          <Stack>
            <Typography
              variant="caption"
              sx={{
                fontSize: '18px',
                color: '#000',
                fontWeight: { xs: '700', lg: '500' },
              }}
            >
              2. Company CC Registration Documents
            </Typography>
            <Typography variant="caption">
              The company CC registration documents
            </Typography>
            <DocumentUploads setFileData={setCompanyRegData} />
          </Stack>
        </Paper>

        {/* 3 */}
        <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
          <Stack>
            <Typography
              variant="caption"
              sx={{
                fontSize: '18px',
                color: '#000',
                fontWeight: { xs: '700', lg: '500' },
              }}
            >
              3. Bank Letter
            </Typography>
            <Typography variant="caption">
              A letter confirming the company bank account
            </Typography>
            <DocumentUploads setFileData={setAddressFileData} />
          </Stack>
        </Paper>

        {/* 4 */}
        <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
          <Stack>
            <Typography
              variant="caption"
              sx={{
                fontSize: '18px',
                color: '#000',
                fontWeight: { xs: '700', lg: '500' },
              }}
            >
              4. Proof of Address
            </Typography>
            <Typography variant="caption">
              A document confirming the company address
            </Typography>
            <DocumentUploads setFileData={setBankFileData} />
          </Stack>
        </Paper>

        <Paper
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'end' },
            alignItems: { xs: 'center', md: 'end' },
            pr: { xs: '10px', md: '25px' },
            pl: { xs: '10px', md: '0px' },
            my: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              width: '100%',
              justifyContent: { xs: 'space-between', md: 'end' },
              alignItems: { xs: 'space-between', md: 'end' },
            }}
          >
            <Button
              disableElevation
              type="button"
              variant="contained"
              sx={{
                mt: 3,
                mb: 3,
                width: { xs: '100%', md: '20%' },

                fontWeight: '500',
                textTransform: 'capitalize',
                fontFamily: 'Inter',
              }}
              onClick={() => {
                previousStep();
              }}
            >
              Back
            </Button>
            <Button
              disableElevation
              type="submit"
              variant="contained"
              sx={{
                mt: 3,
                mb: 3,
                width: { xs: '100%', md: '20%' },

                ml: { xs: 0, lg: 2 },
                fontWeight: '500',
                textTransform: 'capitalize',
                fontFamily: 'Inter',
              }}
            >
              Continue
            </Button>
          </Box>
        </Paper>
      </form>
    </Grid>
  );
}

export default DocumentUpload;
