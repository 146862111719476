// AccordionComponent.tsx

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Box,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CardsComponent from '../cards/CardsComponents';
import Faqs from '../faqs/Faqs';

// Define the structure for trading hours
interface TradingHour {
    day: string;
    time: string;
}

// Define the structure for store data
interface Store {
    title: string;
    holidayNotice?: string[]; // Optional array of strings
    address: string;
    phone: string;
    tradingHours: TradingHour[];
    mapUrl: string;
}

// Define the props for AccordionComponent
interface AccordionComponentProps {
    title: string;
    stores?: Store[];
    displayFAQs?: boolean;
    questions?: any; // Define a proper type based on your Faqs component
    isTop?: string | number; // Updated to accept string or number
    panelNumber: number;
    expanded: string | false;
    handleChange: (
        panel: string
    ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const AccordionComponent: React.FC<AccordionComponentProps> = ({
                                                                   title,
                                                                   stores = [],
                                                                   displayFAQs = false,
                                                                   questions = [],
                                                                   isTop = 0,
                                                                   panelNumber,
                                                                   expanded,
                                                                   handleChange,
                                                               }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                top: isTop,
            }}
        >
            <Accordion
                elevation={0}
                expanded={expanded === `panel${panelNumber}`}
                onChange={handleChange(`panel${panelNumber}`)}
                sx={{
                    width: { xs: '100%', lg: '80%' },
                    px: { xs: '0', lg: '50px' },
                }}
            >
                <AccordionSummary
                    expandIcon={
                        expanded === `panel${panelNumber}` ? (
                            <RemoveCircleOutlineOutlinedIcon />
                        ) : (
                            <AddCircleOutlineOutlinedIcon />
                        )
                    }
                    aria-controls={`panel${panelNumber}bh-content`}
                    id={`panel${panelNumber}bh-header`}
                    sx={{
                        borderBottom:
                            expanded === `panel${panelNumber}`
                                ? 'none'
                                : '#000 solid 1px',
                    }}
                >
                    <Typography
                        variant='h1'
                        sx={{
                            fontSize: '32px',
                            fontWeight: '500',
                            lineHeight: '28px',
                            color: '#101828',
                            mt: 1,
                        }}
                    >
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        borderBottom: '#000 1px solid',
                    }}
                >
                    <AccordionDetails
                        sx={{ width: { xs: '100%', lg: '100%' } }}
                    >
                        <Grid container spacing={4}>
                            {displayFAQs ? (
                                <Faqs questions={questions} />
                            ) : (
                                stores.map((store: Store, index: number) => (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'unset',
                                            justifyContent: 'unset',
                                            m: 0,
                                        }}
                                    >
                                        <CardsComponent
                                            title={store.title}
                                            holidayNotice={store.holidayNotice}
                                            tradingHours={store.tradingHours}
                                            phone={store.phone}
                                            address={store.address}
                                            mapUrl={store.mapUrl}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </AccordionDetails>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default AccordionComponent;
