import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

function SelectField({
  register,
  id,
  itemList,
  defaultValue,
  label,
  isRequired,
  errors,
  errorMsg,
  marginTop,
  disabled,
  isMultiSelect = false,
}: any): JSX.Element {
  const selectDefaultValue =
    isMultiSelect && !Array.isArray(defaultValue)
      ? defaultValue.split(',').map((item: any) => item.trim())
      : defaultValue;
  return (
    <Box sx={{ minWidth: 120, mt: marginTop }}>
      <FormControl size="small" fullWidth>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          id={id}
          labelId={id}
          defaultValue={selectDefaultValue}
          label={label}
          multiple={isMultiSelect}
          {...register(`${id}`, { required: isRequired })}
          error={errors[id] ? true : false}
          disabled={disabled}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {isMultiSelect ? (
                Array.isArray(selected) && selected.length > 0 ? (
                  selected.map((value: any) => (
                    <Chip
                      key={value}
                      label={value}
                      variant="outlined"
                      size="small"
                    />
                  ))
                ) : (
                  <Typography>No selection</Typography>
                )
              ) : (
                <Typography>{selected as string}</Typography>
              )}
            </Stack>
          )}
        >
          {itemList.map((elem: any, index: number) => (
            <MenuItem key={index} value={elem.name}>
              {elem.name}
            </MenuItem>
          ))}
        </Select>
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
            m: '0 14px',
            mt: '4px',
            display: errors[id] ? 'block' : 'none',
          }}
        >
          {errors[id] ? errorMsg : ''}
        </Typography>
      </FormControl>
    </Box>
  );
}

export default SelectField;
