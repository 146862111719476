// ProductGrid.tsx

import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Pagination from './Pagination';

const ProductGrid = ({
                         productItemCount,
                         getProductsMutation,
                         totalPages,
                         currentPage,
                         previousPage,
                         nextPage,
                         lastPage,
                         firstPage,
                         anchorRef,
                     }: any) => {
    const navigate = useNavigate();

    return (
        <>
            <Box>
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    productItemCount={productItemCount}
                    lastPage={lastPage}
                    firstPage={firstPage}
                />
            </Box>
            <Grid container spacing={2} pl={0} pr={0}>
                {getProductsMutation.isLoading ? (
                    <Box
                        sx={{
                            height: '50rem',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : getProductsMutation.isError ? (
                    <>
                        <Typography>An error occurred, please try a different filter</Typography>
                    </>
                ) : (
                    <>
                        {getProductsMutation?.data
                            ?.sort((a: any, b: any) =>
                                a.StockMaster?.design
                                    .toLowerCase()
                                    .localeCompare(b.StockMaster?.design.toLowerCase())
                            )
                            .map((elem: any, index: number) => (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    key={index}
                                    sx={{
                                        display:
                                            index === getProductsMutation?.data?.length - 1 ? 'none' : '',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: {
                                                    xs: '150px',
                                                    sm: '300px',
                                                },
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `../item_group/${elem?.StockMaster?.item_group}/application/${encodeURIComponent(
                                                        elem?.StockMaster?.application
                                                    )}/collection/${encodeURIComponent(
                                                        elem?.Product?.collection
                                                    )}/design/${encodeURIComponent(
                                                        elem?.StockMaster?.design
                                                    )}/colour/${encodeURIComponent(elem.StockMaster?.colour)}`
                                                );
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                                src={elem?.Product?.primaryImageUrl}
                                                alt="product"
                                            />
                                        </Box>
                                        <Typography
                                            fontWeight="600"
                                            fontSize="18px"
                                            lineHeight={'33px'}
                                            textTransform={'capitalize'}
                                            mt={1}
                                            mb={0}
                                        >
                                            {elem?.StockMaster?.design}
                                        </Typography>
                                        <Typography fontWeight="400" fontSize="16px">
                                            {elem?.StockMaster?.colour}
                                        </Typography>

                                        {elem?.StockMaster?.web_size !== '-' && (
                                            <Typography
                                                fontWeight="400"
                                                fontSize="14px"
                                                mb={1}
                                                fontStyle="italic"
                                            >
                                                {elem?.StockMaster?.web_size}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                    </>
                )}
            </Grid>
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
                productItemCount={productItemCount}
                lastPage={lastPage}
                firstPage={firstPage}
            />
        </>
    );
};

export default ProductGrid;
