import { baseNotificationURL } from './baseUrl';

export const apiMailingListSignUp = async (body: object) => {
    const response = await baseNotificationURL.post(`omnisend/sign-up`, body, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
