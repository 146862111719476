import { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/shared/formFields/InputField';

import {
    Grid,
    Box,
    Typography,
    Paper,
    Stack,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
} from '@mui/material';
import { validationPatterns } from '../../../utilities/ValidtionPatterns';

function References({
    updateStep,
    previousStep,
    formData,
    setFormData,
    step,
}: any) {
    const [pia1, setPia1] = useState<boolean>(false);
    const [pia2, setPia2] = useState<boolean>(false);
    const [pia3, setPia3] = useState<boolean>(false);

    const [terms1, setTerms1] = useState<boolean>(false);
    const [terms2, setTerms2] = useState<boolean>(false);
    const [terms3, setTerms3] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>();

    const formSubmit = handleSubmit((data, e) => {
        e?.preventDefault();

        const dataStructure = [
            {
                company_name_1: data.companyName1,
                phone_1: data.phone1,
                average_purchase_1: data.averagePurchase1,
                pia_1: pia1,
                terms_1: terms1,
            },
            {
                company_name_2: data.companyName2,
                phone_2: data.phone2,
                average_purchase_2: data.averagePurchase2,
                pia_2: pia2,
                terms_2: terms2,
            },
            {
                company_name_3: data.companyName3,
                phone_3: data.phone3,
                average_purchase_3: data.averagePurchase3,
                pia_3: pia3,
                terms_3: terms3,
            },
        ];

        setFormData({
            ...formData,
            trade_references: dataStructure,
        });

        updateStep();
    });

    return (
        <Grid mt={9}>
            <Paper
                sx={{
                    display: { xs: 'flex', lg: 'none' },
                    flexDirection: 'column',
                    p: 3,
                    gap: 5,
                }}
            >
                <Typography variant='caption'>
                    Please supply 3 Fabric Houses, Curtain Workshop or Furniture
                    Factory/Upholsterers you have worked with
                </Typography>
            </Paper>
            <Typography
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    fontSize: '32px',
                    fontWeight: 'bold',
                    lineHeight: '33px',
                    textTransform: 'capitalize',
                    color: '#000',
                }}
            >
                Trade References
            </Typography>
            <Typography
                sx={{
                    display: { xs: 'none', lg: 'block' },
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '20px',
                    color: '#000',
                    mt: '30px',
                }}
            >
                Please supply 3 Fabric Houses, Curtain Workshop or Furniture
                Factory/Upholsterers you have worked with
            </Typography>

            <form noValidate onSubmit={formSubmit}>
                {/* 1 */}
                <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
                    <Stack>
                        <Typography
                            variant='caption'
                            sx={{
                                fontSize: { xs: '20px', lg: '18px' },
                                color: '#000',
                                fontWeight: { xs: '700', lg: '500' },
                            }}
                        >
                            Trade Reference 1
                        </Typography>
                        <Typography mt={2} variant='caption'>
                            Company Name
                        </Typography>
                        <Box sx={{ width: { xs: '100%', lg: '50%' } }}>
                            <InputField
                                defaultValue={
                                    formData?.trade_references[0]
                                        ?.company_name_1
                                }
                                marginTop={1}
                                type='text'
                                id='companyName1'
                                name='companyName1'
                                placeholder='Enter name'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter company name'
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                width: { xs: '100%', lg: '50%' },
                                flexDirection: { xs: 'column', lg: 'row' },
                            }}
                        >
                            <Box
                                sx={{
                                    mr: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Phone
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[0]?.phone_1
                                    }
                                    marginTop={1}
                                    type='tel'
                                    id='phone1'
                                    name='phone1'
                                    placeholder='Enter telephone number'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter telephone number'
                                    validationPattern={validationPatterns.tel}
                                    validationPatternMsg='Please enter a valid telephone number'
                                />
                            </Box>

                            <Box
                                sx={{
                                    ml: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Average amount
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[0]
                                            ?.average_purchase_1
                                    }
                                    marginTop={1}
                                    type='number'
                                    id='averagePurchase1'
                                    name='averagePurchase1'
                                    placeholder='Enter amount'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter amount'
                                />
                            </Box>
                        </Box>
                    </Stack>
                    <FormGroup>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[0]?.pia_1
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        PIA
                                    </Typography>
                                }
                                onChange={() => {
                                    setPia1(!pia1);
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[0]
                                                ?.terms_1
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        Terms
                                    </Typography>
                                }
                                onChange={() => {
                                    setTerms1(!terms1);
                                }}
                            />
                        </Box>
                    </FormGroup>
                </Paper>

                {/* 2 */}
                <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
                    <Stack>
                        <Typography
                            variant='caption'
                            sx={{
                                fontSize: { xs: '20px', lg: '18px' },
                                color: '#000',
                                fontWeight: { xs: '700', lg: '500' },
                            }}
                        >
                            Trade Reference 2
                        </Typography>
                        <Typography mt={2} variant='caption'>
                            Company Name
                        </Typography>
                        <Box sx={{ width: { xs: '100%', lg: '50%' } }}>
                            <InputField
                                defaultValue={
                                    formData?.trade_references[1]
                                        ?.company_name_2
                                }
                                marginTop={1}
                                type='text'
                                id='companyName2'
                                name='companyName2'
                                placeholder='Enter name'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter company name'
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                width: { xs: '100%', lg: '50%' },
                                flexDirection: { xs: 'column', lg: 'row' },
                            }}
                        >
                            <Box
                                sx={{
                                    mr: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Phone
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[1]?.phone_2
                                    }
                                    marginTop={1}
                                    type='tel'
                                    id='phone2'
                                    name='phone2'
                                    placeholder='Enter telephone number'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter telephone number'
                                    validationPattern={validationPatterns.tel}
                                    validationPatternMsg='Please enter a valid telephone number'
                                />
                            </Box>

                            <Box
                                sx={{
                                    ml: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Average amount
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[1]
                                            ?.average_purchase_2
                                    }
                                    marginTop={1}
                                    type='number'
                                    id='averagePurchase2'
                                    name='averagePurchase2'
                                    placeholder='Enter amount'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter amount'
                                />
                            </Box>
                        </Box>
                    </Stack>
                    <FormGroup>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[1]?.pia_2
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        PIA
                                    </Typography>
                                }
                                onChange={() => {
                                    setPia2(!pia2);
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[1]
                                                ?.terms_2
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        Terms
                                    </Typography>
                                }
                                onChange={() => {
                                    setTerms2(!terms2);
                                }}
                            />
                        </Box>
                    </FormGroup>
                </Paper>

                {/* 3 */}
                <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
                    <Stack>
                        <Typography
                            variant='caption'
                            sx={{
                                fontSize: { xs: '20px', lg: '18px' },
                                color: '#000',
                                fontWeight: { xs: '700', lg: '500' },
                            }}
                        >
                            Trade Reference 3
                        </Typography>
                        <Typography mt={2} variant='caption'>
                            Company Name
                        </Typography>
                        <Box sx={{ width: { xs: '100%', lg: '50%' } }}>
                            <InputField
                                defaultValue={
                                    formData?.trade_references[2]
                                        ?.company_name_3
                                }
                                marginTop={1}
                                type='text'
                                id='companyName3'
                                name='companyName3'
                                placeholder='Enter name'
                                isRequired={true}
                                register={register}
                                errors={errors}
                                errorMsg='Please enter company name'
                            />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                width: { xs: '100%', lg: '50%' },
                                flexDirection: { xs: 'column', lg: 'row' },
                            }}
                        >
                            <Box
                                sx={{
                                    mr: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Phone
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[2]?.phone_3
                                    }
                                    marginTop={1}
                                    type='tel'
                                    id='phone3'
                                    name='phone3'
                                    placeholder='Enter telephone number'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter telephone number'
                                    validationPattern={validationPatterns.tel}
                                    validationPatternMsg='Please enter a valid telephone number'
                                />
                            </Box>

                            <Box
                                sx={{
                                    ml: { xs: 0, lg: 1 },
                                    width: { xs: '100%', lg: '50%' },
                                }}
                            >
                                <Typography mt={2} variant='caption'>
                                    Average amount
                                </Typography>
                                <InputField
                                    defaultValue={
                                        formData?.trade_references[2]
                                            ?.average_purchase_3
                                    }
                                    marginTop={1}
                                    type='number'
                                    id='averagePurchase3'
                                    name='averagePurchase3'
                                    placeholder='Enter amount'
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    errorMsg='Please enter amount'
                                />
                            </Box>
                        </Box>
                    </Stack>
                    <FormGroup>
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[2]?.pia_3
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        PIA
                                    </Typography>
                                }
                                onChange={() => {
                                    setPia3(!pia3);
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={
                                            formData?.trade_references[2]
                                                ?.terms_3
                                        }
                                    />
                                }
                                label={
                                    <Typography variant='caption'>
                                        Terms
                                    </Typography>
                                }
                                onChange={() => {
                                    setTerms3(!terms3);
                                }}
                            />
                        </Box>
                    </FormGroup>
                </Paper>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'center', md: 'end' },
                        alignItems: { xs: 'center', md: 'end' },
                        pr: { xs: '10px', md: '25px' },
                        pl: { xs: '10px', md: '0px' },
                        my: '20px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: { xs: 'space-between', md: 'end' },
                            alignItems: { xs: 'space-between', md: 'end' },
                            flexDirection: 'row',
                            gap: '10px',
                        }}
                    >
                        <Button
                            disableElevation
                            variant='contained'
                            onClick={() => previousStep()}
                            sx={{
                                mt: 3,
                                mb: 3,
                                width: { xs: '100%', md: '20%' },
                                fontWeight: '500',
                                textTransform: 'capitalize',
                                fontFamily: 'Inter',
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            disableElevation
                            type='submit'
                            variant='contained'
                            sx={{
                                mt: 3,
                                mb: 3,
                                width: { xs: '100%', md: '20%' },
                                fontWeight: '500',
                                textTransform: 'capitalize',
                                fontFamily: 'Inter',
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                </Paper>
            </form>
        </Grid>
    );
}

export default References;
