import { Box, Grid } from "@mui/material";
import StepTracker from "./StepTracker";
import RegistrationNav from "./RegistrationNav";
import MobileStepTracker from "./MobileStepTracker";

function PageLayout({ children, step }: any) {
  return (
    <>
      <RegistrationNav />
      <Grid container sx={{ height: { xs: "100vh", lg: "95vh" } }}>
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            flex: 2,
            backgroundColor: "#000000",
          }}
        >
          <StepTracker step={step} />
        </Box>

        <Box
          sx={{
            flex: { xs: 12, lg: 8 },
            backgroundColor: "#F2F4F7",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", lg: "none" },
            }}
          >
            <MobileStepTracker step={step} />
          </Box>
          <Box
            sx={{
              mt: { xs: "200px", lg: "0" },
              width: {xs: "100%", lg: "90%"},
            }}
          >
            {children}
          </Box>
        </Box>
        <Box
          sx={{
            flex: { xs: 0, lg: 0 },
            backgroundColor: "#F2F4F7",
          }}
        ></Box>
      </Grid>
    </>
  );
}

export default PageLayout;
