import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import { apiGetCollectionPageData } from '../../../api/apiCollections';
import { apiGetDesignsInCollection } from '../../../api/apiDesigns';
import LoadingScreen from '../loading/LoadingScreen';
import TextHeader from '../../textHeader/TextHeader';
import Gallery from '../gallery/Gallery';
import { useEffect } from 'react';
import MissingPage from '../errorMessages/MissingPage';
import DesignsInCollectionGrid from '../grid/DesignsInCollectionGrid';
import { captureAnalytics } from '../../../utilities/Analytics';
import CarouselBanner from '../banner/CarouselBanner';

function CollectionTemplate() {
  const { item_group, application, collection } = useParams();
  const location = useLocation();

  const argumentDataStructure = {
    category: {
      application: application,
      item_group: item_group,
      collection: collection,
    },
  };

  const designFilters = {
    filter: {
      item_group: item_group,
      application: application,
      range: collection,
    },
    off_setter: {
      start: 0,
      end: 100,
    },
    limited: true,
  };

  const {
    isLoading,
    isError,
    isSuccess,
    data: collectionData,
  } = useQuery([`collection-${collection}`], () =>
    apiGetCollectionPageData(argumentDataStructure)
  );

  const { data: designsData } = useQuery(
    [`collection-designs-${collection}`],
    () => apiGetDesignsInCollection(designFilters)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    captureAnalytics(
      'pageview',
      location.pathname,
      `Collection - ${collection}`
    );
  }, [location.pathname, collection]);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isError && <MissingPage />}

          {isSuccess && (
            <>
              <CarouselBanner
                heroImages={collectionData?.section_consumer_hero}
                heroVideo={collectionData?.section_consumer_hero_video ? {
                  video_url: collectionData.section_consumer_hero_video.video_url,
                  thumbnail_url: collectionData.section_consumer_hero_video.thumbnail_url || ''
                } : undefined}
                heroLink={collectionData?.section_consumer_hero_link}
                videoLink={collectionData?.section_consumer_video_link}
              />
              <TextHeader
                heading={collectionData?.page_title}
                content={collectionData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />
              <Gallery
                images={collectionData?.section_gallery}
                galleryLayout={'masonry'}
                columns={2}
                title={'title'}
                secondaryText={'2bd'}
                hasTitle={false}
                hasSecondaryText={false}
              />
              {/* !Client has requested a change on this component, we may need to revert the DesignsGrid component */}
              <DesignsInCollectionGrid
                data={designsData}
                marginTop="5rem"
                marginBottom="5rem"
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default CollectionTemplate;
