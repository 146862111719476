import { useLocation, useRoutes } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage';
import CategoryTemplate from '../components/shared/page-templates/CategoryTemplate';
import ApplicationTemplate from '../components/shared/page-templates/ApplicationTemplate';
import ProductPage from '../components/shared/page-templates/ProductTemplate/ProductPage';
import WallPaperCalculatorPage from '../pages/WallPaperPage/WallPaperCalculatorPage';
import StoreLocatorPage from '../pages/company/StoreLocatorPage';
import CareerPage from '../pages/company/CareerPage';
import AboutUsPage from '../pages/company/AboutUsPage';
import Navbar from '../components/shared/layout/navbar/Navbar';
import Footer from '../components/shared/layout/footer/Footer';
import CollectionTemplate from '../components/shared/page-templates/CollectionTemplate';
import BrandsTemplate from '../components/shared/page-templates/BrandsTemplate';
import BrandsPage from '../pages/Brands/BrandsPage';
import ResponsibilityPage from '../pages/company/ResponsibiltyPage';
import DirectorsPage from '../pages/company/DirectorsPage';
import InternationalsPage from '../pages/InternationalsPage/InternationalsPage';
import RegistrationPage from '../pages/RegistrationPage/RegistrationPage';
import ApplicationTypeTemplate from '../components/shared/page-templates/ApplicationTypeTemplate';
import ApplicationTypeCategoryTemplate from '../components/shared/page-templates/src/components/shared/pageTemplates/ApplicationTypeCategoryTemplate';

const AppRoutes = () => {
    const routes = [
        { path: '/', element: <HomePage /> },
        {
            path: 'brand',
            element: <BrandsPage />,
        },
        {
            path: 'register',
            element: <RegistrationPage />,
        },

        {
            path: 'item_group/:item_group/brand/:brand',
            element: <BrandsTemplate />,
        },

        {
            path: 'responsibilities',
            element: <ResponsibilityPage />,
        },

        {
            path: 'directors',
            element: <DirectorsPage />,
        },

        { path: 'item_group/:item_group', element: <CategoryTemplate /> },
        {
            path: 'item_group/:item_group/application/:application',
            element: <ApplicationTemplate />,
        },
        {
            path: 'item_group/:item_group/application/:application/collection/:collection',
            element: <CollectionTemplate />,
        },

        // ! Users can access the product page via design or design and colour
        {
            path: 'item_group/:item_group/application/:application/collection/:collection/design/:design',
            element: <ProductPage />,
        },

        {
            path: 'item_group/:item_group/application/:application/collection/:collection/design/:design/colour/:colour',
            element: <ProductPage />,
        },

        { path: 'company', element: <StoreLocatorPage /> },
        { path: 'about-us', element: <AboutUsPage /> },
        { path: 'careers', element: <CareerPage /> },
        { path: 'store-locator', element: <StoreLocatorPage /> },
        { path: 'wallpaper-calculator', element: <WallPaperCalculatorPage /> },
        { path: 'internationals', element: <InternationalsPage /> },
        {
            path: 'item_group/:item_group/application_type/:application_type',
            element: <ApplicationTypeTemplate />,
        },
        {
            path: 'item_group/:item_group/application_type/:application_type/category_name/:category_name',
            element: <ApplicationTypeCategoryTemplate />,
        },
    ];

    const element = useRoutes([...routes]);
    const location = useLocation();
    const displayNavFooter = location.pathname !== '/register';
    return (
        <>
            {displayNavFooter && <Navbar />}
            {element}
            {displayNavFooter && <Footer />}
        </>
    );
};

export default AppRoutes;
