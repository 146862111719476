import { Typography, Box } from '@mui/material';

const Vacancy = ({ careersData, index }: any) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Position
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.position}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Area
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.location}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Position available from
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.available_from}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Description
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.description}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Main purpose of the job
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.purpose}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Minimum requirements
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.requirements}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Ideal candidate
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.candidate}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Main duties
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.duties}
            </Typography>
            <Typography
                sx={{
                    color: 'rgba(16, 24, 40, 1)',
                    fontSize: '24px',
                    fontFamily: 'Gill Sans',
                }}
            >
                Email
            </Typography>
            <Typography sx={{ fontSize: 16, color: 'rgba(71, 84, 103, 1)' }}>
                {careersData[index]?.email}
            </Typography>
        </Box>
    );
};
export default Vacancy;
