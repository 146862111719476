import { Grid, Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissingContent from '../errorMessages/MissingContent';

function CategoriesGrid({
                          data,
                          hasTitles,
                          hasButtons,
                          hasBackgroundText,
                          spacingValue,
                          marginTop,
                          marginBottom,
                        }: any) {
  const navigate = useNavigate();

  // ** Application Mapping **
  const applicationMapping: { [key: string]: string[] } = {
    'Curtaining': ['Curtaining', 'Dual Purpose'],
    'Dual Purpose': ['Dual Purpose'],
    'Upholstery': ['Upholstery', 'Dual Purpose'],
  };

  const handleNavigate = (applicationName: string) => {
    const applications = applicationMapping[applicationName] || [applicationName];
    const searchParams = new URLSearchParams();
    applications.forEach((app) => searchParams.append('application', app));

    navigate(`/item_group/005/application/${applicationName.toLowerCase()}?${searchParams.toString()}`);
  };

  return (
      <>
        {data === null || data.length === 0 ? (
            <MissingContent sectionName="Item Group Section" />
        ) : (
            <Container maxWidth="xl">
              <Grid
                  container
                  rowSpacing={'16px'}
                  columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
                  sx={{ mt: marginTop, mb: marginBottom }}
              >
                {data.map((elem: any, index: number) => (
                    <Grid key={index} item xs={12} sm={6} md={spacingValue}>
                      <Box
                          sx={{
                            position: 'relative',
                            cursor: 'pointer',
                            width: '100%',
                            height: { xs: '320px', lg: '510px' },
                            minHeight: '100%',
                            '&:hover img': {
                              transform: 'scale(1.03)',
                            },
                            overflow: 'hidden',
                          }}
                          onClick={() => handleNavigate(elem.application_name)}
                      >
                        <img
                            src={elem?.url}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              background:
                                  'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.83) 100%)',
                              transition: 'transform 0.5s ease',
                            }}
                            alt="categories"
                        />
                        {hasBackgroundText && (
                            <Box
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  justifyContent: 'center',
                                  background:
                                      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                                }}
                            >
                              <Typography
                                  variant="h6"
                                  sx={{
                                    textAlign: 'center',
                                    fontWeight: 'lighter',
                                    color: '#fff',
                                    fontSize: '40px',
                                    textTransform: 'capitalize',
                                    mb: { xs: 3, lg: 4 },
                                  }}
                              >
                                {elem.application_name.replace(/_/g, ' ')}
                              </Typography>
                            </Box>
                        )}
                      </Box>

                      <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: '1.5rem',
                          }}
                      >
                        {hasTitles && (
                            <Typography variant="h5">{elem?.item_group}</Typography>
                        )}
                        {hasButtons && (
                            <Button
                                sx={{
                                  borderRadius: '1px',
                                  padding: '0.5rem 2.5rem',
                                  margin: '1.5rem 0rem',
                                }}
                                variant="outlined"
                                onClick={() => handleNavigate(elem.application_name)}
                            >
                              Explore More
                            </Button>
                        )}
                      </Box>
                    </Grid>
                ))}
              </Grid>
            </Container>
        )}
      </>
  );
}

export default CategoriesGrid;
