import { TextField } from '@mui/material';
function InputField({
  defaultValue,
  type,
  id,
  name,
  placeholder,
  errors,
  register,
  errorMsg,
  validationPattern,
  validationPatternMsg,
  isRequired,
  marginTop,
  size,
  disabled,
  value,
}: any) {
  return (
    <>
      <TextField
        sx={{ mt: marginTop, width: '100%' }}
        type={type}
        size={size ? size : 'small'}
        defaultValue={defaultValue}
        id={id}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        {...register(`${id}`, {
          required: isRequired,
          pattern: validationPattern && {
            value: validationPattern,
            message: validationPatternMsg,
          },
        })}
        error={errors[name] ? true : false}
        helperText={
          errors[name]
            ? errors[name].type === 'pattern'
              ? validationPatternMsg
              : errorMsg
            : ''
        }
      />
    </>
  );
}
export default InputField;
