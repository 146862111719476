import * as React from 'react';
import { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Paper,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import '../../../styles/App.css';
function Introduction({ updateStep }: any) {
  const [tradeMemberValue, setTradeMemberValue] = useState('no');
  const [tradeReferenceValue, setTradeReferenceValue] = useState('yes');
  const [qualificationValue, setQualificationValue] = useState('yes');

  const handleTradeMemberChange = (
    event: React.ChangeEvent<HTMLElement>,
    newTradeMemberValue: string
  ) => {
    setTradeMemberValue(newTradeMemberValue);
  };

  const handleTradeReferenceChange = (
    event: React.ChangeEvent<HTMLElement>,
    newtradeReferenceValue: string
  ) => {
    setTradeReferenceValue(newtradeReferenceValue);
  };

  const handleQualificationValueChange = (
    event: React.ChangeEvent<HTMLElement>,
    newQualificationValue: string
  ) => {
    setQualificationValue(newQualificationValue);
  };

  return (
    <Grid
      sx={{
        mt: { xs: 0, lg: 9 },
        pb: { xs: '20px', lg: '0' },
      }}
    >
      <Paper
        sx={{
          display: { xs: 'flex', lg: 'none' },
          flexDirection: 'column',
          p: 3,
          gap: 5,
        }}
      >
        <Typography variant="caption">
          Thank you for your interest in joining our network of trade
          professionals.
        </Typography>
        <Typography variant="caption">
          Please complete the following to determine if you meet the qualifying
          criteria.
        </Typography>
      </Paper>
      <Typography
        sx={{
          display: { xs: 'none', lg: 'flex' },
          fontSize: '32px',
          fontWeight: 'bold',
          lineHeight: '33px',
          textTransform: 'None',
          color: '#000',
        }}
      >
        Thank you for your interest in joining our network of trade
        professionals.
      </Typography>
      <Typography
        sx={{
          display: { xs: 'none', lg: 'flex' },
          fontSize: '22px',
          fontWeight: 'bold',
          lineHeight: '20px',
          color: '#344054',
          mt: '30px',
        }}
      >
        Please complete the following to determine if you meet the qualifying
        criteria.
      </Typography>

      <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
        <Stack>
          <Typography variant="caption">
            Are you a member of the Trade? (architect, curtain workshop,
            decorator, designer, furniture/homeware retailer, procurement
            company, related export company, specifier, stockist,
            upholsterer/furniture manufacturer).
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', lg: 'row' },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={tradeMemberValue === 'yes'}
                  onChange={(e) =>
                    handleTradeMemberChange(e, e.target.checked ? 'yes' : 'no')
                  }
                  color="primary"
                />
              }
              label={<Typography variant="caption">Yes</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={tradeMemberValue === 'no'}
                  onChange={(e) =>
                    handleTradeMemberChange(e, e.target.checked ? 'no' : 'yes')
                  }
                  color="primary"
                />
              }
              label={<Typography variant="caption">No</Typography>}
            />
          </Box>
        </Stack>
      </Paper>

      {/* 2 */}
      {tradeMemberValue === 'yes' && (
        <>
          <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
            <Stack>
              <Typography variant="caption">
                Can you provide at least three Trade references? E.g.: fabric
                houses, curtain workshop or furniture factory/upholsterer
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tradeReferenceValue === 'yes'}
                      onChange={(e) =>
                        handleTradeReferenceChange(
                          e,
                          e.target.checked ? 'yes' : 'no'
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<Typography variant="caption">Yes</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tradeReferenceValue === 'no'}
                      onChange={(e) =>
                        handleTradeReferenceChange(
                          e,
                          e.target.checked ? 'no' : 'yes'
                        )
                      }
                      color="primary"
                    />
                  }
                  label={<Typography variant="caption">No</Typography>}
                />
              </Box>
            </Stack>
          </Paper>

          {/* 3 */}
          {tradeReferenceValue === 'no' && (
            <Paper elevation={1} sx={{ mt: 5, p: 3 }}>
              <Stack>
                <Typography variant="caption">
                  Can you provide proof of an interior design or similar
                  qualification?
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      lg: 'row',
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={qualificationValue === 'yes'}
                        onChange={(e) =>
                          handleQualificationValueChange(
                            e,
                            e.target.checked ? 'yes' : 'no'
                          )
                        }
                        color="primary"
                      />
                    }
                    label={<Typography variant="caption">Yes</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={qualificationValue === 'no'}
                        onChange={(e) =>
                          handleQualificationValueChange(
                            e,
                            e.target.checked ? 'no' : 'yes'
                          )
                        }
                        color="primary"
                      />
                    }
                    label={<Typography variant="caption">No</Typography>}
                  />
                </Box>
              </Stack>
            </Paper>
          )}
        </>
      )}

      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'end',
          pr: '24px',
          py: '16px',
          borderTopRightRadius: '0px',
          borderTopLeftRadius: '0px',
          mt: '-2px',
        }}
      >
        <Button
          disabled={
            tradeMemberValue === 'no' ||
            tradeMemberValue === null ||
            tradeReferenceValue === null ||
            qualificationValue === 'no' ||
            qualificationValue === null
          }
          variant="contained"
          sx={{
            mt: 2,
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: '500',
            fontFamily: 'Inter, sans-serif',
            color: '#FFF',
          }}
          onClick={() => {
            updateStep();
          }}
          disableElevation
        >
          Continue
        </Button>
      </Paper>

      {qualificationValue === 'no' && (
        <Paper elevation={1} sx={{ my: 5, p: 3, backgroundColor: '#000000' }}>
          <Stack>
            <Box sx={{ display: 'flex' }}>
              <ErrorIcon sx={{ mr: 1, color: '#FF3030' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#fff',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: '#FFF',
                  }}
                >
                  Sorry - You currently don’t qualify to apply for a Hertex
                  Trade account
                </Typography>
                <Typography
                  mt={1}
                  variant="caption"
                  sx={{
                    color: '#FFF',
                  }}
                >
                  Contact us on info@hertex.co.za for contact details of a
                  member of trade in your area
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Paper>
      )}
    </Grid>
  );
}

export default Introduction;
