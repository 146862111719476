export const TermsAndConditions = [
    {"text": ""},
    {"text": "RETURN POLICIES ON FABRICS, RUGS AND HOMEWARE"},
    {"text": "• All prices exclude VAT."},
    {"text": "• Because exchange rates fluctuate, Hertex reserves the right to change prices from time to time without prior notification."},
    {"text": "• All goods remain the property of Hertex until full payment has reflected."},
    {"text": "• Due to increased fuel costs, we cannot guarantee the prompt delivery of individual orders of less than three (3) metres."},
    {"text": "• Customers may arrange collection from our showrooms or warehouse."},
    {"text": "• Official order numbers/names must be quoted on all orders."},
    {"text": "• The minimum quantity to be cut for any fabric order is one (1) metre."},
    {"text": "• Back orders are subject to the price ruling at the time of delivery."},
    {"text": "• Delivery dates for goods are approximate. As such, Hertex cannot be held responsible for any delays."},
    {"text": "• Products bought at sale or shop-soiled products cannot be refunded, returned or exchanged."},
    {"text": "• Made-to-order products will not be considered for refund, exchange or credit."},
    {"text": ""},

    {"text": ""},
    {"text": "RETURN POLICY ON FABRIC"},
    {"text": "• Fabric cannot be returned after thirty (30) calendar days from the date of invoice, unless the product becomes defective within six (6) months from the date of invoice (assuming it has been used for its general intended purposes)."},
    {"text": "• No credits (price differences, etc.) will be given after thirty (30) working days from the date of the original invoice."},
    {"text": "• No quality complaints or shortages will be accepted after fifteen (15) working days after the date of invoice."},
    {"text": "• No returns will be allowed on fabric that is less than six (6) metres long."},
    {"text": "• Discontinued fabric or fabric on sale cannot be returned."},
    {"text": "• Hertex will not accept responsibility for fabric quantities. Please have a professional workshop provide an estimate before placing your order."},
    {"text": "• We do not accept exchanges or returns for items damaged by a curtain or upholsterer workshop."},
    {"text": "• Fabric is produced in batches - please order fabric accordingly, as dye-lot variations may occur."},
    {"text": "• No returns will be accepted without a valid reason provided in writing and approval from a sales manager."},
    {"text": "• Credits can be given only once the central distribution centre has received the fabric and required documentation."},
    {"text": "• No returns will be accepted without a copy of the original invoice."},
    {"text": "• A handling fee will be charged on all returned fabric unless otherwise agreed. The handling fee for velvet returns is 50%; all other fabric returns include a 30% handling fee."},
    {"text": "• Customers must examine the product against its generally intended purpose and normal use before accepting delivery in order to determine whether the fabric is of good quality and reasonably matches the description or sample."},
    {"text": "• Once the fabric has been cut or made up, it cannot be returned."},
    {"text": "• Fabric with a width variation of ± 3% cannot be returned for credit."},
    {"text": "• Dye-lot variations occur in all textiles and are unavoidable. Customers must examine the product against its generally intended purchase and normal use before accepting delivery thereof. Subtle textures, slubs, colour and weaving irregularities associated with natural fibre textiles in particular are not considered to be defects."},
    {"text": "• Hertex cannot be held responsible for any loss or damage suffered by a customer as a result of the product having been incorrectly cleaned or handled."},
    {"text": "• No claims will be accepted if fabric has been chemically treated after purchase."},
    {"text": "• Pilling is characteristic of many synthetic upholstery fabrics. This happens due to excess fibres migrating to the surface and forming a ball. This is not a defect and Hertex will not accept any responsibility for pilling. It is accepted as fair wear and tear in line with the general intended purpose and normal use."},
    {"text": "• Hertex will not accept any responsibility for shortages once the fabric has been cut or made up."},
    {"text": "• SABS quality standards on flaws and defects will apply (five to six flaws per 50 metres are deemed acceptable)."},
    {"text": "• Hertex reserves the right to return the fabric to the customer if alleged defect or flawed fabric falls within accepted commercial tolerance."},
    {"text": "• Hertex will not accept any responsibility for fabrics fading as a result of exposure to harsh sunlight. Fading as a result of exposure to harsh sunlight will be accepted as fair wear and tear in line with the general intended purpose and normal use. This is especially important to note when fabric is used for blinds, as unlined curtains, when lining is used as curtaining or when sub-standard or non-block-out fabric is used."},
    {"text": "• No returns of International fabrics will be accepted unless the product is defective."},
    {"text": "• Hertex will not be held responsible for any consequential loss as a result of a defective or hazardous product."},
    {"text": "• If any fabric hangers, memos or books taken out on appro are not returned within the specified time period, Hertex will charge the relevant client’s account with the full replacement value thereof."},
    {"text": "• Hertex reserves the right to refuse any return in line with the above-mentioned terms."},
    {"text": "• For goods to be credited, a customer needs to supply Hertex with the following documentation and must note the following:"},
    {"text": "o Copy of invoice"},
    {"text": "o Proof of return (signed copy of delivery note)"},
    {"text": "o Written reason for return, signed off by Hertex representative"},
    {"text": "o Name of Hertex contact person who approved credit"},
    {"text": "o The refund will be made in the same tender as the original payment"},
    {"text": "o The credit card holder must be present for exchange/return"},
    {"text": "• For goods to be credited, the item must be returned in its original packaging, with the shipping label attached (wholesale)."},
    {"text": ""},

    {"text": ""},
    {"text": "RETURN POLICY ON WALLPAPER"},
    {"text": "• Wallpaper cannot be returned after thirty (30) calendar days from the date of invoice, unless the product becomes defective within six (6) months from the date of invoice (assuming it has been used for its general intended purpose)."},
    {"text": "• No credits (price differences, etc.) will be given after thirty (30) working days from the date of the original invoice."},
    {"text": "• No quality complaints or shortages will be accepted after fifteen (15) days after the date of invoice."},
    {"text": "• Hertex will not accept responsibility for shortages once the wallpaper has been cut."},
    {"text": "• The minimum return quantity for wallpapers is three (3) rolls of one design and colour; in addition, the wallpaper must be from the same batch (dye-lot)."},
    {"text": "• Discontinued or sale wallpaper cannot be returned."},
    {"text": "• Hertex will not accept responsibility for wallpaper quantities. Please have a professional installer provide an accurate estimate before placing the final order."},
    {"text": "• We do not accept exchanges or returns for items damaged by a wallpaper installer."},
    {"text": "• Wallpaper rolls are produced in batches - please order per room, as dye-lot variations may occur."},
    {"text": "• Hertex reserves the right to return the wallpaper to the customer if alleged defects or flaws falls within accepted commercial tolerance."},
    {"text": "• No returns of International wallpaper will be accepted, unless the product is defective."},
    {"text": "• No returns will be accepted without a valid reason provided in writing and approval from a sales manager."},
    {"text": "• Credits can be given once the central distribution centre has received the wallpaper and required documentation."},
    {"text": "• No returns will be accepted without a copy of the original invoice."},
    {"text": "• A handling fee of 30% will be charged on all wallpaper, unless otherwise agreed."},
    {"text": "• Customers must examine the product against its generally intended purpose and normal use before accepting delivery in order to determine whether the wallpaper is of good quality and reasonably matches the description or sample."},
    {"text": "• Once wallpaper has been cut, made up or installed, it cannot be returned."},
    {"text": "• No wallpaper claims will be accepted if the correct glue was not used or the installation instructions were not followed."},
    {"text": "• No claims will be accepted if the wallpaper has been chemically treated after purchase."},
    {"text": "• Wallpaper with a width variation of ± 3% cannot be returned for credit."},
    {"text": "• Hertex cannot be held responsible for any loss or damage suffered by a customer as a result of the product having been incorrectly cleaned or handled."},
    {"text": "• Dye-lot variations occur in wallpaper and are unavoidable. Subtle textures, slubs, colour and weaving irregularities associated with wallpaper (especially natural fibres, such as grasscloth wallpapers) are not considered to be defects."},
    {"text": "• Hertex will not accept any responsibility for wallpaper fading as a result of exposure to harsh sunlight. Fading as a result of exposure to sunlight will be accepted as fair wear and tear in line with the general intended purpose and normal use."},
    {"text": "• Hertex will not be held responsible for any consequential loss as a result of a defective or hazardous product."},
    {"text": "• Hertex reserves the right to refuse any return in line with the above-mentioned terms."},
    {"text": "• For goods to be credited, a customer needs to supply Hertex with the following documentation and must note the following:"},
    {"text": "o Copy of invoice"},
    {"text": "o Copy of invoice"},
    {"text": "o Proof of return (signed copy of delivery note)"},
    {"text": "o Written reason for return, signed off by Hertex representative"},
    {"text": "o Name of Hertex contact person who approved credit"},
    {"text": "o The refund will be made in the same tender as the original payment"},
    {"text": "o The credit card holder must be present for exchange/return"},
    {"text": "• For goods to be credited, the item has to be returned in its original packaging, with the shipping label attached (wholesale)."},
    {"text": ""},

    {"text": ""},
    {"text": "RETURN POLICY ON RUGS AND HOMEWARE ITEMS"},
    {"text": "• Rugs and homeware cannot be returned after thirty (30) calendar days from the date of invoice, unless the product becomes defective within six (6) months from the date of invoice (assuming it has been used for its general intended purpose)."},
    {"text": "• In the unlikely event of a product being defective, it may be returned within six (6) months after date of invoice, or twelve (12) months for furniture items, provided the product has been kept in good condition during the period, has been used for its intended purpose, and cared for according to any instructions that accompanied the goods."},
    {"text": "• For public health reasons, we cannot accept returns on the following items: bed linen and bedding; towels; crockery and cutlery; and cushion inners, unless in sellable condition and in the original packaging."},
    {"text": "• Products can be returned to Hertex showrooms or Haus stores, or collected by our courier for return. (Please note, transport costs will apply.)"},
    {"text": "• All products received for return/exchange consideration will be inspected before authorisation is granted for exchange/refund or credit."},
    {"text": "• No credits (price differences, etc.) will be given after thirty (30) days from the date of the original invoice."},
    {"text": "• No quality complaints will be accepted fifteen (15) days after the date of invoice."},
    {"text": "• Discontinued or sale items cannot be returned."},
    {"text": "• No returns will be accepted without a valid reason provided in writing and approval from a sales manager."},
    {"text": "• Rugs with a width variation of ± 3% cannot be returned for credit."},
    {"text": "• Credits can be granted only once the central distribution centre has received the rug or homeware item and the required documentation, and an inspection has been completed to ensure that the item is in its original condition."},
    {"text": "• No returns will be accepted without a copy of the original invoice."},
    {"text": "• Cancelled and/or returned items are subject to handling fees of R250.00 (excluding VAT per item). If a number of goods are being returned, special transport arrangements will need to be made, and a quotation will be submitted to the customer; these charges must be paid by the customer. However, if the goods are found to be defective, the supplier will refund the customer the collection charge. If stock was requested from the distribution centre to be sent out, the relevant transport costs will be charged if the rug or homeware item is returned. If a non-defective rug or homeware item is returned to the distribution centre, the relevant transport costs will be apply."},
    {"text": "• Dye-lot variations occur among all rugs/homeware items, and are unavoidable. Subtle textures, slubs, colour and weaving irregularities associated with items (especially those made of natural fibres) are not considered to be defects. The customer must examine the product before accepting delivery thereof."},
    {"text": "• Viscose rugs tend to shed for the first few weeks, as excess fibres not woven into the base come loose. Regular vacuuming is recommended a few times a week until shedding stops. The vacuuming process is very important as it helps to get rid of the loose fibres quicker. This will not compromise the quality of the rug in any way as it is only the excess fibres coming out, not the fibres woven into the design."},
    {"text": "• Hertex cannot be held responsible for any loss or damage suffered by a customer as a result of the product having been incorrectly cleaned or handled."},
    {"text": "• No claims will be accepted if rugs or homeware items have been chemically treated after purchase."},
    {"text": "• Hertex will not accept any responsibility for rugs or homeware items fading as a result of direct exposure to sunlight. Fading as a result of exposure to sunlight is considered fair wear and tear and in line with the normal end use for which the rug or homeware item is generally intended."},
    {"text": "• Hertex will not be held responsible for any consequential loss as a result of a defective or hazardous product."},
    {"text": "• Rugs should be used with an anti-slip underlay or secured to avoid accidents when rugs are placed on slippery surfaces."},
    {"text": "• Hertex reserves the right to refuse any return in line with the above-mentioned terms."},
    {"text": "• For goods to be credited, a customer needs to supply Hertex with the following documentation and must note the following:"},
    {"text": "  o Copy of invoice"},
    {"text": "  o Proof of return (signed copy of delivery note)"},
    {"text": "  o Written reason for return, signed off by a Hertex representative"},
    {"text": "  o Name of Hertex contact person who approved credit"},
    {"text": "  o The refund will be made in the same tender as the original payment"},
    {"text": "  o The credit card holder must be present for exchange/return"},
    {"text": "• For goods to be credited, the item has to be returned in its original packaging, with the shipping label attached (wholesale)."},
    {"text": ""},

];
