import { useEffect, useLayoutEffect } from 'react';
import { useParams, useLocation, useNavigationType } from 'react-router-dom';
import FilterComponent from '../../../components/shared/filter/FilterComponent';
import { useQuery } from 'react-query';
import { apiGetApplicationPageData } from '../../../api/apiApplications';
import LoadingScreen from '../../../components/shared/loading/LoadingScreen';
import FeaturedGallery from '../gallery/FeaturedGallery';
import TextHeader from '../../textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import MissingPage from '../errorMessages/MissingPage';
import CollectionsGrid from '../grid/CollectionsGrid';
import CarouselBanner from '../banner/CarouselBanner';
import { captureAnalytics } from '../../../utilities/Analytics';

const ApplicationTemplate = () => {
  const { item_group, application } = useParams();
  const location = useLocation();
  const navigationType = useNavigationType();

  const argumentDataStructure = {
    category: { application: application, item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>(
      [`application-${application}`],
      () => apiGetApplicationPageData(argumentDataStructure),
      {
        staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
        refetchOnWindowFocus: false,
        refetchOnMount: false, // Do not refetch on mount if data is cached
      }
  );

  // Scroll to top when pathname changes via PUSH navigation
  useLayoutEffect(() => {
    if (navigationType === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, navigationType]);

  // Manually restore scroll position on POP navigation
  useLayoutEffect(() => {
    if (navigationType === 'POP') {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition));
        sessionStorage.removeItem('scrollPosition');
      }
    }
  }, [navigationType]);

  // Save scroll position before unmounting
  useEffect(() => {
    return () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };
  }, []);

  // Capitalize the application string
  const capitalizeApplicationString = (application: string | undefined) => {
    if (!application) return '';
    return application
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  };

  const applicationString = capitalizeApplicationString(application);

  // Track page view for analytics
  useEffect(() => {
    captureAnalytics('pageview', location.pathname, `application-${application}`);
  }, [location.pathname, application]);

  if (isError) {
    return <MissingPage />;
  }

  return (
      <>
        {isLoading ? (
            <LoadingScreen />
        ) : (
            <>
              {isSuccess && (
                  <>
                    <CarouselBanner
                      heroImages={applicationData?.section_consumer_hero}
                      heroVideo={applicationData?.section_consumer_hero_video ? {
                        video_url: applicationData.section_consumer_hero_video.video_url,
                        thumbnail_url: applicationData.section_consumer_hero_video.thumbnail_url
                      } : undefined}
                      heroLink={applicationData?.section_consumer_hero_link}
                      videoLink={applicationData?.section_consumer_video_link}
                    />

                    <TextHeader
                        heading={applicationData?.page_title}
                        content={applicationData?.description}
                        isCentered={false}
                        width="70%"
                        marginTop={10}
                        marginBottom={5}
                    />

                    <FeaturedGallery
                        images={applicationData?.section_featured_collection}
                        hasVideo={false}
                        invert={false}
                        marginTop={5}
                        marginBottom={5}
                        title={applicationData?.featured_collection_title?.collection_name}
                        description={applicationData?.featured_collection_description}
                        itemGroup={applicationData?.featured_collection_title?.item_group_code}
                        application={applicationData?.featured_collection_title?.application_name}
                        collection={applicationData?.featured_collection_title?.collection_name}
                    />

                    <FilterComponent itemGroup={item_group} title={applicationString} />

                    <CollectionsGrid
                        data={applicationData?.section_collections_in_application}
                        applicationName={`${application}`}
                        marginTop={5}
                        marginBottom={5}
                    />

                    <NewArrivalsGrid
                        header="New arrivals"
                        data={applicationData?.section_new_arrivals}
                        hasTitles={true}
                        hasButtons={true}
                        hasBackgroundText={false}
                        spacingValue={4}
                        marginTop={'5rem'}
                        marginBottom={'5rem'}
                    />
                  </>
              )}
            </>
        )}
      </>
  );
};

export default ApplicationTemplate;
