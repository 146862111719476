import { Box, Typography } from "@mui/material";
import navLogo from "../../../assets/logos/hertex-logo-long.png";

const MobileStepTracker = ({ step }: any) => {
  const formSteps = [
    "Introduction",
    "Company Details",
    "Trade References",
    "Document Upload",
    "Declaration",
  ];
  return (
    <Box
      sx={{
        background: "#FFF",
        pt: "30px",
        pb: "20px",
        px: "10px",
        position: "fixed",
        width: "100%",
        zIndex: 999,
        borderColor: "#FFF",
      }}
    >
      <Box
        sx={{
          width: "150px",
          pb: "10px",
        }}
      >
        <img
          src={navLogo}
          alt="nav logo"
          style={{
            width: "100%",
            cursor: "pointer",
          }}
        />
      </Box>
      <Box
        sx={{
          background: "#000",
          color: "#FFF",
          borderRadius: "4px",
          p: "17px 16px 12px 16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "FFF",
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            {formSteps[step]}
          </Typography>
          <Typography
            sx={{
              color: "FFF",
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {step + 1}/{formSteps.length}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#989898",
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "400",
          }}
        >
          * Indicates a required field
        </Typography>
      </Box>
    </Box>
  );
};

export default MobileStepTracker;
