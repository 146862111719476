import { useState, useEffect, useRef, useMemo } from "react";
import { Box } from "@mui/material";
import "./CarouselBanner.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissingContent from "../errorMessages/MissingContent";

interface CarouselBannerProps {
  heroImages?: Array<{ url: string }>;
  heroVideo?: {
    video_url: string;
    thumbnail_url: string;
  };
  heroLink?: string;
  videoLink?: string;
}

function CarouselBanner({
                          heroImages = [],
                          heroVideo,
                          heroLink = '',
                          videoLink = ''
                        }: CarouselBannerProps) {
  const initialItems = useMemo(() => {
    if (heroVideo?.video_url) {
      return [
        {
          url: heroVideo.video_url,
          isVideo: true,
          link: videoLink
        },
        ...heroImages.filter(img => img?.url).map(img => ({
          url: img.url,
          isVideo: false,
          link: heroLink
        }))
      ];
    }

    return heroImages.filter(img => img?.url).map(img => ({
      url: img.url,
      isVideo: false,
      link: heroLink
    }));
  }, [heroVideo, heroImages, heroLink, videoLink]);

  const [items, setItems] = useState(initialItems);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const newItems = heroVideo?.video_url
      ? [
        {
          url: heroVideo.video_url,
          isVideo: true,
          link: videoLink
        },
        ...heroImages.filter(img => img?.url).map(img => ({
          url: img.url,
          isVideo: false,
          link: heroLink
        }))
      ]
      : heroImages.filter(img => img?.url).map(img => ({
        url: img.url,
        isVideo: false,
        link: heroLink
      }));

    setItems(newItems);
  }, [heroImages, heroVideo, heroLink, videoLink]);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const previous = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (!item?.url) return;

      if (item.isVideo) {
        const video = document.createElement("video");
        video.src = item.url;
      } else {
        const img = new Image();
        img.src = item.url;
      }
    });
  }, [items]);

  useEffect(() => {
    if (items.length === 0) return;

    const interval = setInterval(() => {
      const currentItem = items[currentIndex];
      if (!currentItem) return;

      if (currentItem.isVideo && videoRef.current) {
        videoRef.current.play().catch(() => {
          console.warn('Video playback failed');
          next(); // Move to next item if video fails
        });
      } else {
        next();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, items]);

  const handleVideoEnd = () => {
    if (items.length > 1) {
      next();
    } else if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {
        console.warn('Video replay failed');
      });
    }
  };

  const handleItemClick = (link: string) => {
    if (link) {
      window.location.href = link;
    }
  };

  return (
    <Box>
      {items.length === 0 ? (
        <MissingContent sectionName="Banner Carousel" />
      ) : (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%", // 16:9 aspect ratio
          }}
        >
          {items.map((item, index) => (
            <Box
              key={index}
              className={`slide ${index === currentIndex ? "active" : ""}`}
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                cursor: item.link ? "pointer" : "default",
              }}
              onClick={() => handleItemClick(item.link)}
            >
              {item.isVideo ? (
                <video
                  ref={videoRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                  }}
                  onEnded={handleVideoEnd}
                  onError={() => next()}
                  muted
                  autoPlay
                  playsInline
                >
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                  }}
                  src={item.url}
                  alt=""
                  onError={() => next()}
                />
              )}
            </Box>
          ))}
          {items.length > 1 && (
            <>
              <ArrowBackIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  left: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={previous}
              />
              <ArrowForwardIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  right: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={next}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CarouselBanner;