import { Box, Grid, Typography, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AdjustIcon from "@mui/icons-material/Adjust";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function StepTracker({ step }: any) {
  const formSteps = [
    "Introduction",
    "Company Details",
    "Trade References",
    "Document Upload",
    "Declaration",
  ];

  return (
    <Grid sx={{ color: "#fff" }}>
      <Stack sx={{m: '5rem 15px 0px 15px' }}>
        {formSteps.map((elem: any, index: number) => (
          <Box key={index} sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {step === index ? (
                <AdjustIcon />
              ) : step > index ? (
                <CheckCircleIcon sx={{ color: "#4CA30D" }} />
              ) : (
                <FiberManualRecordIcon />
              )}
              {index < 4 && <Typography sx={{ ml: "10px" }}>|</Typography>}
            </Box>

            <Typography ml={2} textTransform="uppercase" fontSize={'15px'}>
              {elem}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Grid>
  );
}

export default StepTracker;
