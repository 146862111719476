import interioVideo from '../assets/home-barners/videos/interioVideo.mp4';
import Hero_1 from '../assets/home-barners/Hero_1.jpg';
import Hero_2 from '../assets/home-barners/Hero_2.jpg';
import Hero_3 from '../assets/home-barners/Hero_3.jpg';
import hertexLogo from '../assets/logos/hertex-logo-long.png';
import internationalsImage from '../assets/internationals_image.png';
import directorImages from '../assets/directors.png';
import categoryImage from '../assets/Category.png';
import directorsImagesTwo from '../assets/directorsImgsTwo.png';
import tradeImage from '../assets/trade_image.jpeg';
import lemieux from '../assets/internationals/newCatalogues/HXI_Lemieux Et Cie_York_ISSUU Pages_Cover.jpg';
import grassclothNatural from '../assets/internationals/newCatalogues/HXI_Grasscloth & Natural Resources_York_ISSUU Pages_Cover.jpg';
import urbanDigest from '../assets/internationals/newCatalogues/HXI_Urban Digest_York_ISSUU Pages_Cover.jpg';
import breeganJane from '../assets/internationals/newCatalogues/HXI_Breegan Jane, Paradiso, Secret Garden, Evora, Marina_C&C_ISSUU Pages_Cover.jpg';
import vividoUrban from '../assets/internationals/newCatalogues/HXI_Vivido Urban Whitworth_C&C_Cover.jpg';
import alfresco from '../assets/internationals/newCatalogues/HXI_Alfresco William Morris_C&C_Cover.jpg';
import midori from '../assets/internationals/newCatalogues/HXI_Midori_C&C_ISSUU pages_Cover.jpg';
import wallDesigns from '../assets/internationals/newCatalogues/HXI_Wall Designs 4_Masureel_ISSUU Pages_Cover.jpg';
import masureelJoy from '../assets/internationals/newCatalogues/Masureel_Joy.jpg';
import masureelRock from '../assets/internationals/newCatalogues/Masureel_Rock.jpg';
import masureelTerra from '../assets/internationals/newCatalogues/Masureel_Terra.jpg';
import harlequinSophieRobinson from '../assets/internationals/newCatalogues/Harlequin_Sophie Robinson.jpg';
import reflect from '../assets/internationals/newCatalogues/HXI_Reflect_Harlequin_Cover.jpg';
import sheers1 from '../assets/internationals/newCatalogues/HXI_Sheers_Harlequin_ISSUU Pages_Cover.jpg';
import editeurLinenQuarter from '../assets/internationals/newCatalogues/Editeur_Linen Quarter.jpg';
import carnabyStreet from '../assets/internationals/newCatalogues/HXI_Carnaby Street_Cover.jpg';
import gilesDeacon from '../assets/internationals/newCatalogues/HXI_Giles Deacon_Sanderson_ISSUU Pages_Cover.jpg';
import perfectPlains from '../assets/internationals/newCatalogues/HXI_Perfect Plains_Brewster_Cover.jpg';
import artifacts from '../assets/internationals/newCatalogues/HXI_Artifacts_York_Press Release_ISSUU-images-0.jpg'
import formations from '../assets/internationals/newCatalogues/HXI_Formations_York_Press Release_ISSUU-images-0.jpg'
import montecito from '../assets/internationals/newCatalogues/HXI_Montecito_York_Press Release_ISSUU_00001.jpg'

export const HertexLogo = [
  {
    img: hertexLogo,
  },
];

export const trade = [
  {
    img: tradeImage,
  },
];

export const directorsPageImages = [
  {
    img: directorsImagesTwo,
    title: 'Name Surname',
    subTitle: 'DESIGNATION',
    secondaryText:
      'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 1',
    id: '1',
  },
  {
    img: directorsImagesTwo,
    title: 'Name Surname',
    subTitle: 'DESIGNATION',
    secondaryText:
      'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 2',
    id: '2',
  },
  {
    img: directorsImagesTwo,
    title: 'Name Surname',
    subTitle: 'DESIGNATION',
    secondaryText:
      'lorem ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas 3',
    id: '3',
  },
];

export const internationals = [
  {
    img: internationalsImage,
  },
];

export const aboutUsData = [
  {
    img: categoryImage,
    title: 'Our Partners',
    buttonText: 'VIEW NOW',
    paragraph:
      'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
    id: '1',
    link: '../directors',
  },
  {
    img: categoryImage,
    paragraph:
      'Founded by Hertex, HAUS was created to give customers instant access to an expertly curated collection of homeware to complete their interior schemes',
    buttonText: 'FIND A STORE',

    title: 'Explore Our Spaces',
    id: '2',
    link: '../store-locator',
  },
];

export const newCataloguesBookData = [
  { img: 'bookImg', title: 'animal attraction' },
  { img: 'bookImg', title: 'animal attraction' },
  { img: 'bookImg', title: 'animal attraction' },
];

export const directorImgs = [
  {
    img: directorImages,
  },
];

export const carouselImages = [
  {
    url: 'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  },
  {
    url: 'https://images.unsplash.com/photo-1616486338812-3dadae4b4ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80',
  },
  {
    url: 'https://images.unsplash.com/photo-1556228453-efd6c1ff04f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  },
  {
    url: 'https://images.unsplash.com/photo-1540574163026-643ea20ade25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  },
  {
    url: 'https://images.unsplash.com/photo-1600121848594-d8644e57abab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  },
];

export const carouselVideos = [
  {
    id: 1,
    title: 'Welcome',
    src: 'carouselVideo',
    description: 'To the Hertex website',
  },
];

export const categoryImages = [
  {
    img: 'fabricCategoryImg',
    title: 'Fabrics',
    item_group: '005',
    url_title: 'fabric',
    secondaryText: 'Secondary 1',
    id: '1',
  },
  {
    img: 'wallpaperCategoryImg',
    title: 'Wall Coverings',
    item_group: '001',
    url_title: 'wallpaper',
    secondaryText: 'Secondary 2',
    id: '2',
  },
  {
    img: 'rugsCategoryImg',
    title: 'Rugs',
    item_group: '004',
    url_title: 'rugs',
    secondaryText: 'Secondary 3',
    id: '3',
  },
  {
    img: 'homeCategoryImg',
    title: 'Homeware',
    item_group: '005',
    url_title: 'homeware',
    secondaryText: 'Secondary 3',
    id: '4',
  },
];

export const carouselHomeData = [
  { img: Hero_1, homePageTitle: 'The element Collection' },
  { img: Hero_2 },
  { img: Hero_3 },
];

export const companySubMenuData = [
  {
    title: 'about us',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    subMenuId: 1,
  },
  {
    title: 'our partners',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    subMenuId: 2,
  },
  {
    title: 'explore out spaces',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    subMenuId: 3,
  },
  {
    title: 'careers',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    subMenuId: 4,
  },
  {
    title: 'responsibilities',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
    subMenuId: 4,
  },
];

export const fabricsSubMenuData = [
  {
    title: 'upholstery',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
  },
  {
    title: 'dual purpose',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
  },
  {
    title: 'curtaining',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
  },
  {
    title: 'outdoor',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
  },
  {
    title: 'easy to clean',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli',
  },
];

export const carouselVideosData = [
  {
    id: 1,
    title: 'Welcome',
    src: interioVideo,
    description: 'To Hertex',
  },
];

export const catalogueData = [
  {
    id: '01',
    img: lemieux,
    url: 'https://issuu.com/hertexhaus/docs/hxi_lemieux_et_cie_york_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '02',
    img: grassclothNatural,
    url: 'https://issuu.com/hertexhaus/docs/hxi_grasscloth_natural_resources_york_issuu_page?fr=xKAE9_zU1NQ',
  },
  {
    id: '03',
    img: urbanDigest,
    url: 'https://issuu.com/hertexhaus/docs/hxi_urban_digest_york_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '04',
    img: breeganJane,
    url: 'https://issuu.com/hertexhaus/docs/hxi_breegan_jane_paradiso_secret_garden_evora_?fr=xKAE9_zU1NQ',
  },
  {
    id: '05',
    img: vividoUrban,
    url: 'https://issuu.com/hertexhaus/docs/hxi_vivido_urban_whitworth_c_c_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '06',
    img: alfresco,
    url: 'https://issuu.com/hertexhaus/docs/hxi_alfresco_william_morris_c_c_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '07',
    img: midori,
    url: 'https://issuu.com/hertexhaus/docs/hxi_midori_c_c_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '08',
    img: wallDesigns,
    url: 'https://issuu.com/hertexhaus/docs/hxi_wall_designs_4_masureel_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '09',
    img: masureelJoy,
    url: 'https://issuu.com/hertexhaus/docs/hxi_joy_masureel_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '10',
    img: masureelRock,
    url: 'https://issuu.com/hertexhaus/docs/hxi_rock_masureel_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '11',
    img: masureelTerra,
    url: 'https://issuu.com/hertexhaus/docs/hxi_terra_masureel_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '12',
    img: harlequinSophieRobinson,
    url: 'https://issuu.com/hertexhaus/docs/hxi_sophie_robinson_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '13',
    img: reflect,
    url: 'https://issuu.com/hertexhaus/docs/hxi_reflect_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '14',
    img: sheers1,
    url: 'https://issuu.com/hertexhaus/docs/hxi_sheers_harlequin_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '15',
    img: editeurLinenQuarter,
    url: 'https://issuu.com/hertexhaus/docs/hx_linen_quarter_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '16',
    img: carnabyStreet,
    url: 'https://issuu.com/hertexhaus/docs/hx_carnaby_street_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '17',
    img: gilesDeacon,
    url: 'https://issuu.com/hertexhaus/docs/hxi_giles_deacon_sanderson_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '18',
    img: perfectPlains,
    url: 'https://issuu.com/hertexhaus/docs/hxi_perfect_plains_brewster_issuu_pages?fr=xKAE9_zU1NQ',
  },
  {
    id: '19',
    img: artifacts,
    url: 'https://issuu.com/hertexhaus/docs/hertex_internationals_york_artifacts_wallpaper_col?fr=xKAE9_zU1NQ',
  },
  {
    id: '20',
    img: formations,
    url: 'https://issuu.com/hertexhaus/docs/hertex_internationals_york_formations_wallpaper_co?fr=xKAE9_zU1NQ',
  },
  {
    id: '21',
    img: montecito,
    url: 'https://issuu.com/hertexhaus/docs/hertex_internationals_york_montecito_wallpaper_col?fr=xKAE9_zU1NQ',
  },
  
  
];
