import { Grid, Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissingContent from '../errorMessages/MissingContent';

function HomewareCategoryGrid({
    data,
    spacingValue,
    marginTop,
    marginBottom,
}: any) {
    const navigate = useNavigate();

    return (
        <>
            {data === null || data.length === 0 ? (
                <MissingContent sectionName='Item Group Section' />
            ) : (
                <Container maxWidth='xl'>
                    <Grid
                        container
                        rowSpacing={'16px'}
                        columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
                        sx={{ mt: { marginTop }, mb: { marginBottom } }}
                    >
                        {data.map((elem: any, index: number) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={spacingValue}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        width: '100%',
                                        height: { xs: '320px', lg: '510px' },
                                        minHeight: '100%',
                                    }}
                                    onClick={() => {
                                        navigate(
                                            `${
                                                elem?.application_name === ' '
                                                    ? `../item_group/${elem?.item_group_code}`
                                                    : `../item_group/${elem?.item_group_code}/application/${elem.application_name}`.toLowerCase()
                                            }`,
                                        );
                                    }}
                                >
                                    <img
                                        src={elem?.url}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            background:
                                                'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.83) 100%)',
                                        }}
                                        alt='categories'
                                    />

                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            justifyContent: 'center',
                                            background:
                                            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)",
                                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                textAlign: 'center',
                                                fontWeight: 'lighter',
                                                color: '#fff',
                                                fontSize: '40px',
                                                textTransform: 'capitalize',
                                                mb: {xs:3 , lg:4}, 
                                            }}
                                        >
                                            {elem?.application_name === ' '
                                                ? elem?.item_group
                                                : elem?.application_name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            )}
        </>
    );
}

export default HomewareCategoryGrid;
