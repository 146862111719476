import { baseNotificationURL } from './baseUrl';

export const apiRegistration = async (data: object) => {
    try {
        const response = await baseNotificationURL.post(
            `consumer/request-user-registration`,
            {
                data,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data.json;
    } catch (error: any) {
        throw new Error(error?.message);
    }
};
