import { baseURL } from "./baseUrl";

export const apiGetSingleBrand = async (data: object) => {
  const response = await baseURL.post(`get-all-cms-data`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};

export const apiGetAllBrands = async () => {
  const response = await baseURL.get(`get-all-brands`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
};
