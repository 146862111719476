import axios from 'axios';

// Use for local development
// const localUrl = 'https://product.hertex.co.za/';

// const localNotificationUrlStaging = 'https://notification.hertex.co.za/';

// Use for production

// const url = window.location.href;

let url = window.location.origin;

let cleanUrl = url.replace(/^https?:\/\/(www\.)?/, '');

// const dotIndex = url.indexOf('.');

// const slashIndex = url.indexOf('/', dotIndex);

// const result = url.substring(dotIndex + 1, slashIndex);

// const prodUrl = `https://product.${result}/`;

// const prodNotificationUrl = `https://notification.${result}`;

const updatedProdUrl = `https://product.${cleanUrl}/`;

const updatedProdNotificationUrl = `https://notification.${cleanUrl}`;

export const baseURL = axios.create({
    baseURL: updatedProdUrl,
});

export const baseNotificationURL = axios.create({
    baseURL: updatedProdNotificationUrl,
});
