import { Box, Grid, Typography, Divider } from '@mui/material';

const ProductDetails = ({ productDetailsList }: any) => {
  return (
    <>
      <Grid
        sx={{ width: '100%' }}
        container
        justifyContent="center"
        alignItems="center"
      ></Grid>
      <Grid container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            mt: 3,
            pl: { xs: '0', lg: '50px' },
              mb: '3em',
          }}
        >
          <Typography
            mb={2}
            variant="h5"
            fontSize={'40px'}
            fontWeight={'500'}
            lineHeight={'48px'}
          >
            Product Details
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {productDetailsList.map((elem: any, index: number) => (
              <Box key={index}>
                  <Box
                      sx={{
                          display:
                              elem?.value?.props?.children?.length === 0 ||
                              elem.value === 'N/A' ||
                              elem.value === '-' ||
                              elem.value === ''
                                  ? 'none'
                                  : 'flex',
                          justifyContent: 'flex-start', // Align items at start
                          alignItems: 'center', // Optional: vertically center align if needed
                          gap: 2, // Add a small gap between columns instead of space-between
                      }}
                  >
                      <Box
                          sx={{
                              width: '25%', // Fixed smaller width for the first column
                              flexShrink: 0, // Prevent this box from shrinking below 20%
                          }}
                      >
                          <Typography
                              sx={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                              }}
                          >
                              {elem.key}
                          </Typography>
                      </Box>

                      <Box
                          sx={{
                              flex: 1, // This will allow the second column to fill remaining space
                              maxWidth: '60%',
                          }}
                      >
                          <Typography
                              sx={{
                                  fontSize: '16px',
                                  fontWeight: '400',
                              }}
                          >
                              {elem.value}
                          </Typography>
                      </Box>
                  </Box>
                <Divider
                  sx={{
                    mt: 1,
                    mb: 1,
                    display:
                      elem?.value?.props?.children?.length === 0 ||
                      elem.value === 'N/A' ||
                      elem.value === '-' ||
                      elem.value === ''
                        ? 'none'
                        : 'flex',
                  }}
                  variant="fullWidth"
                  color="black"
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        ></Box>
      </Grid>
    </>
  );
};

export default ProductDetails;
