import ReactGA from 'react-ga4'

export const captureAnalytics = (hitType: string, page: string, title: string) => {
    ReactGA.send({
        hitType: hitType,
        page: page,
        title: title,
    });

}
