import { baseURL } from './baseUrl';

export const apiGetApplicationPageData = async (category: object) => {
    const response = await baseURL.post(`get-all-cms-data`, category, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
