import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

const DesignsInCollectionGrid = ({ data, marginBottom, marginTop }: any) => {
    const navigate = useNavigate();
    data = data?.filter((item: any) => item?.Product && item?.StockMaster);

    return (
        <>
            <Box
                sx={{
                    mt: marginTop,
                    mb: marginBottom,
                    ml: { xs: '5%', lg: '5rem' },
                    mr: { xs: '5%', lg: '5rem' },
                }}
            >
                <Typography
                    variant='h4'
                    mb={2}
                    fontWeight='400'
                    fontSize={'56px'}
                >{`Designs in this collection`}</Typography>

                <Typography mt={1} mb={2} fontWeight='bold'></Typography>

                <Grid container spacing={'12px'}>
                    {data
                        ?.sort((a: any, b: any) =>
                            a.StockMaster?.design
                                .toLowerCase()
                                .localeCompare(
                                    b.StockMaster?.design.toLowerCase()
                                )
                        )
                        .map((elem: any, index: number) => (
                            <Grid key={index} item xs={12} sm={6} lg={2}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',

                                        height: '100%',
                                        width: { xs: '100%', lg: '100%' },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            height: '350px',
                                            width: '100%',
                                            '&:hover img': {
                                                transform: 'scale(1.03)',
                                            },
                                            overflow: 'hidden',
                                        }}
                                        onClick={() => {
                                            navigate(
                                                `../item_group/${elem?.StockMaster?.item_group}/application/${elem?.StockMaster?.application}/collection/${elem?.StockMaster?.range}/design/${elem?.StockMaster?.design}/colour/${elem.StockMaster?.colour}`
                                            );
                                        }}
                                    >
                                        <img
                                            src={elem?.Product?.primaryImageUrl}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                transition:
                                                    'transform 0.5s ease',
                                            }}
                                            alt='categories'
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography
                                            mt={1}
                                            fontWeight={'600'}
                                            fontSize={'18px'}
                                            textTransform={'capitalize'}
                                            lineHeight={'27px'}
                                        >
                                            {' '}
                                            {elem?.StockMaster?.design}
                                        </Typography>
                                        <Typography
                                            mb={2}
                                            fontWeight={'400'}
                                            fontSize={'16px'}
                                            textTransform={'capitalize'}
                                            lineHeight={'27px'}
                                        >
                                            {' '}
                                            {elem?.Product?.colour}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            background: '#F6F6F6',
                                            padding: '8px',
                                            display: 'none',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    ></Box>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </Box>
        </>
    );
};

export default DesignsInCollectionGrid;
