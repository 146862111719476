import { Grid } from "@mui/material";

function ProductStepper({ length, currentIndex }: any) {
  const stepsArray: object[] = [];

  for (let i = 0; i < length; i++) {
    let obj: object = { amt: i };
    stepsArray.push(obj);
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        background: "rgba(255, 255, 255, 0.80)",
        width: "auto",
        px: "10px",
        py: "10px",
        borderRadius: "4px",
      }}
    >
      {stepsArray.map((item: any, index: number) => (
        <Grid
          key={index}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: item.amt === currentIndex ? "#000" : "#919191",
            color: "#000",
            height: "10px",
            width: "10px",
            border: "1px solid #919191",
            margin: "0px 5px",
            borderRadius: "100%",
          }}
        ></Grid>
      ))}
    </Grid>
  );
}

export default ProductStepper;
