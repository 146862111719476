import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../../components/shared/formFields/InputField';
import GenericTextModal from '../../../components/shared/modal/GenericTextModal';
import { TermsAndConditions } from '../../../components/shared/text/TermsAndConditions';
import {
  Grid,
  Box,
  Typography,
  Paper,
  Stack,
  Checkbox,
  FormGroup,
  Button,
  Divider,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { useMutation } from 'react-query';
import { apiRegistration } from '../../../api/apiRegistration';
import { CheckCircle, Error } from '@mui/icons-material';
import StatusModal from '../../../components/shared/modal/StatusModal';
import { validationPatterns } from '../../../utilities/ValidtionPatterns';
function Declaration({
  formData,
  setFormData,
  setFormIsSubmitted,
  previousStep,
}: any) {
  const [isMarketing, setIsMarketing] = useState<string>('no');

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const mutation = useMutation(apiRegistration, {
    onSuccess: () => {
      setFormIsSubmitted(true);
      setStatusOpen(true);
    },
    onError: () => {
      setStatusOpen(true);
    },
  });

  const formSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    const dataStructure = {
      name: data.name,
      title: data.title,
      email: data.email,
      terms_accepted: termsAccepted,
      marketing: isMarketing,
    };

    setFormData({
      ...formData,
      declaration: dataStructure,
    });

    mutation.mutate({
      ...formData,
      declaration: dataStructure,
    });

    setFormIsSubmitted(true);
  });

  return (
    <>
      {mutation.isError && (
        <StatusModal
          icon={
            <Error
              sx={{
                color: 'red',
                width: '100%',
                height: '100%',
              }}
            />
          }
          setStatusOpen={() => setStatusOpen(false)}
          statusOpen={statusOpen}
          statusHeader="Error"
          statusText="Registration request not sent successfully."
          buttonText="Contact us"
          link="mailto:info@hertex.co.za"
        />
      )}
      {mutation.isSuccess && (
        <StatusModal
          icon={
            <CheckCircle
              sx={{
                color: 'green',
                width: '100%',
                height: '100%',
              }}
            />
          }
          setStatusOpen={() => setStatusOpen(false)}
          statusOpen={statusOpen}
          statusHeader="Success"
          statusText="Registration request sent successfully."
          buttonText="Return home"
          link="/"
        />
      )}

      <form onSubmit={formSubmit}>
        <Paper
          sx={{
            display: { xs: 'flex', lg: 'none' },
            flexDirection: 'column',
            p: 3,
            gap: 5,
          }}
        >
          <Typography variant="caption">
            Last step! Please make sure you’ve read and understood the following
          </Typography>
        </Paper>

        <Typography
          sx={{
            display: { xs: 'none', lg: 'block' },
            color: '#000',
            fontSize: '40px',
            fontWeight: 'bold',
          }}
        >
          Declaration
        </Typography>
        <Typography
          sx={{
            display: { xs: 'none', lg: 'block' },
            color: '#000',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          Last step! Please make sure you’ve read and understood the following
        </Typography>
        <Paper elevation={1} sx={{ mt: 5, p: 3, mb: { xs: '20px', lg: '0' } }}>
          <Stack>
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', lg: '50%' },
                flexDirection: { xs: 'column', lg: 'row' },
              }}
            >
              <Box
                sx={{
                  mr: { xs: 0, lg: 1 },
                  width: { xs: '100%', lg: '50%' },
                }}
              >
                <Typography mt={2} variant="caption">
                  Full Name
                </Typography>
                <InputField
                  defaultValue={formData?.company_details?.owner_name}
                  marginTop={1}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  isRequired={true}
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your name"
                />
              </Box>

              <Box
                sx={{
                  ml: { xs: 0, lg: 1 },
                  width: { xs: '100%', lg: '50%' },
                }}
              >
                <Typography mt={2} variant="caption">
                  Company Name
                </Typography>
                <InputField
                  defaultValue={formData?.company_details?.company_name}
                  marginTop={1}
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter your company name"
                  isRequired={true}
                  register={register}
                  errors={errors}
                  errorMsg="Please enter your company name"
                />
              </Box>
            </Box>
            <Typography mt={2} variant="caption">
              I, the undersigned, hereby declare that the above information is
              correct and that I have full legal capacity and authorization for
              the placing of orders and the payment thereof and hereby bind
              myself jointly and severally as co-principal debtor for the
              punctual payment of all sums due or becoming due according to the
              letters as stated above. I further agree to the return policies of
              HERTEX.
            </Typography>
            <Typography mt={2} variant="caption">
              By entering into this agreement, you agree to the following terms
              and conditions:
            </Typography>
            <FormGroup>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 2,
                  ml: '-10px',
                }}
              >
                <Checkbox
                  defaultChecked={formData?.trade_references[0]?.cod_1}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
                <Typography variant="caption">
                  I have read and understood the{' '}
                  <span
                    style={{
                      textDecorationLine: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    {' '}
                    terms and conditions
                  </span>
                  *Your privacy is important to us. As such, we comply with the
                  requirements of the POPI Act.
                </Typography>
              </Box>
            </FormGroup>
            <Typography mt={2} variant="caption">
              Do you consent to receiving Hertex marketing content
            </Typography>
            <FormGroup>
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMarketing === 'yes'}
                      onChange={(e) =>
                        setIsMarketing(e.target.checked ? 'yes' : 'no')
                      }
                    />
                  }
                  label={<Typography variant="caption">Yes</Typography>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMarketing === 'no'}
                      onChange={(e) =>
                        setIsMarketing(e.target.checked ? 'no' : 'yes')
                      }
                    />
                  }
                  label={<Typography variant="caption">No</Typography>}
                />
              </Box>
            </FormGroup>

            <Box
              sx={{
                mr: { xs: 0, lg: 1 },
                width: { xs: '100%', lg: '50%' },
                mt: 2,
              }}
            >
              <Typography mt={2} variant="caption">
                Email address
              </Typography>
              <InputField
                defaultValue={''}
                marginTop={1}
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                isRequired={true}
                register={register}
                errors={errors}
                errorMsg="Please enter your email address"
                validationPattern={validationPatterns.email}
                validationPatternMsg="Please enter a valid email address"
              />
            </Box>
            <Divider sx={{ p: '0 -20px', m: '2rem -20px' }} />
            <Grid
              container
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent={{ xs: 'center', md: 'end' }}
              gap={'10px'}
              width={'100'}
            >
              <Grid item>
                <Button
                  fullWidth={true}
                  variant="contained"
                  onClick={() => previousStep()}
                  disableElevation
                  sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    width: '100%',
                    color: '#FFF',
                    textTransform: 'capitalize',
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  disabled={!termsAccepted}
                  disableElevation
                  sx={{
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    color: '#FFF',
                    width: '100%',

                    textTransform: 'capitalize',
                  }}
                >
                  {mutation.isLoading ? (
                    <CircularProgress
                      sx={{
                        color: '#FFF',
                      }}
                    />
                  ) : (
                    'Submit Application'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Paper>

        <GenericTextModal
          title="Terms and Conditions"
          content={TermsAndConditions}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </form>
    </>
  );
}

export default Declaration;
