import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Box,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Vacancy from '../vacancies/Vacancy';

const CareersAccordion = ({ careersData }: any) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {careersData
                ?.sort((a: any, b: any) =>
                    a.region.toLowerCase().localeCompare(b.region.toLowerCase())
                )
                .map((elem: any, index: number) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                        <Accordion
                            elevation={0}
                            expanded={expanded === `panel-${elem?.region}`}
                            onChange={handleChange(`panel-${elem?.region}`)}
                            sx={{
                                width: { xs: '100%', lg: '80%' },
                                px: { xs: '0', lg: '50px' },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expanded === `panel-${elem?.region}` ? (
                                        <RemoveCircleOutlineOutlinedIcon />
                                    ) : (
                                        <AddCircleOutlineOutlinedIcon />
                                    )
                                }
                                aria-controls='panel-content'
                                id='panel-header'
                                sx={{
                                    borderBottom:
                                        expanded === `panel-${elem?.region}`
                                            ? 'none'
                                            : '#000 solid 1px',
                                }}
                            >
                                <Typography
                                    variant='h1'
                                    sx={{
                                        fontSize: '32px',
                                        fontWeight: '500',
                                        lineHeight: '28px',
                                        color: '#101828',
                                        mt: 1,
                                    }}
                                >
                                    {elem?.region}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                    borderBottom: '#000 1px solid',
                                }}
                            >
                                <AccordionDetails
                                    sx={{ width: { xs: '100%', lg: '100%' } }}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} lg={12}>
                                            <Vacancy
                                                careersData={careersData}
                                                index={index}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
        </>
    );
};

export default CareersAccordion;
