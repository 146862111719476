import { baseURL } from './baseUrl';

export const apiGetProducts = async (
    item_group: string,
    application: string,
    collection: string,
    design: string
) => {
    const response = await baseURL.get(
        `products/item_group/design/get_all_products/${item_group}/${application}/${collection}/${design}`
    );
    return response.data;
};

export const apiGetSingleProduct = async (stock_link: string) => {
    const response = await baseURL.get(`product/${stock_link}`);
    return response.data;
};
