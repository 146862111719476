import { Box, Typography } from '@mui/material';
//We avoided using the dangerouslySetInnerHTML due to security warnings/risks.

const Faqs = ({ questions = [] }: { questions?: any[] }) => {
    const links = [
        {
            name: 'careers@hertex.co.za',
            address: 'careers@hertex.co.za',
            type: 'email',
        },
        {
            name: 'LinkedIn',
            address:
                'https://www.linkedin.com/company/hertexfabrics/mycompany/',
            type: 'website',
        },
        {
            name: 'careersinland@hertex.co.za',
            address: 'careersinland@hertex.co.za',
            type: 'email',
        },
    ];

    const findLinksName = (text: any) => {
        return text.split(' ').map((textWord: any, index: any) => {
            const link = links.find((link) => textWord.includes(link.name));
            if (link) {
                return (
                    <a
                        key={index}
                        href={
                            link.type === 'email'
                                ? `mailto:${link.address}`
                                : link.address
                        }
                        style={{
                            textDecoration: 'underline',
                            color: '#0e1b20',
                        }}
                    >
                        {textWord}
                    </a>
                );
            }
            return <span key={index}>{textWord} </span>;
        });
    };

    return (
        <Box sx={{ mt: 2, ml: 2, width: { xs: '100%', lg: '85%' } }}>
            {questions.map((question: any, index: any) => (
                <Box key={index}>
                    <Typography
                        sx={{
                            mb: '8px',
                        }}
                    >
                        {findLinksName(question.text_1)}
                    </Typography>

                    <Typography>{findLinksName(question.text_2)}</Typography>
                </Box>
            ))}
        </Box>
    );
};

export default Faqs;
