import TextHeader from "../../components/textHeader/TextHeader";

import { directorImgs, directorsPageImages } from "../../api/tempData";

import MailingListSignup from "../../components/shared/mailing/Mailing";
import CompanyGrid from "../../components/shared/grid/CompanyGrid";
import CompanyHausGrid from "../../components/shared/grid/CompanyHausGrid";

const data = {
  directorsPageImages: directorsPageImages,
};

const DirectorsPage = () => {
  return (
    <>
      <TextHeader
        heading="Directors Profiles"
        isCentered={true}
        width="70%"
        marginTop={10}
        marginBottom={5}
      />

      <CompanyGrid
        marginTop={15}
        marginBottom={15}
        image={directorImgs}
        isReverse={false}
        heading="Name"
        hasButtons={false}
        headingTwo="Surname"
      />

      <CompanyHausGrid
        data={data.directorsPageImages}
        hasTitles={true}
        hasButtons={false}
        hasBackgroundText={false}
        spacingValue={4}
        marginTop={"5rem"}
        marginBottom={"5rem"}
        isTextCenter={true}
        isParagraphReverse={false}
      />

      <CompanyGrid
        marginTop={15}
        marginBottom={15}
        image={directorImgs}
        isReverse={true}
        heading="Name"
        headingTwo="Surname"
      />

      <CompanyHausGrid
        data={data.directorsPageImages}
        hasTitles={true}
        hasButtons={false}
        paragraph="ok"
        subheader="sub"
        hasBackgroundText={false}
        spacingValue={4}
        marginTop={"5rem"}
        marginBottom={"5rem"}
        isTextCenter={true}
        isParagraphReverse={false}
        isTextAlign={false}
      />

      <MailingListSignup />
    </>
  );
};

export default DirectorsPage;
