import { Typography, Grid, Box, Button } from '@mui/material';

function CompanyTextHeader({
    heading,
    content,
    subContent,
    isCentered,
    width,
    marginTop,
    marginBottom,
    hasButton,
    buttonText,
    isColor,
    isWidthChange,
}: any) {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid
                    mt={marginTop}
                    mb={marginBottom}
                    p={{xs: '0 0px 20px 0px', lg: '50px'}}
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        width: {xs: '100%', lg: isWidthChange ? '80%' : '100%'},
                        background: isColor ? '#FFF' : 'transparent',
                    }}
                >
                    <Box
                        sx={{
                            width: {xs:'90%', lg: '100%'},
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            px: {xs: '0', lg: '20px'}
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: '100%', lg: width },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: isCentered
                                    ? 'center'
                                    : 'flex-start',
                                alignItems: isCentered
                                    ? 'center'
                                    : 'flex-start',
                            }}
                        >
                            <Typography
                                mb={3}
                                variant='h3'
                                fontWeight={'300'}
                                textTransform={'none'}
                                sx={{
                                    fontSize: {xs: '40px', lg: '56px'}
                                }}
                            >
                                {heading}
                            </Typography>

                            <Typography
                                fontSize={{xs: '18px', lg:'24px'}}
                                fontWeight={'300'}
                                lineHeight={'26px'}
                                sx={{
                                    textAlign: isCentered ? 'center' : '',
                                    textTransform: 'none',
                                }}
                            >
                                {content}
                            </Typography>

                            <Typography
                                mt={2}
                                fontSize={{xs: '18px', lg:'24px'}}
                                fontWeight={'300'}
                                lineHeight={'26px'}
                                sx={{
                                    textAlign: isCentered ? 'center' : '',
                                    textTransform: 'none',
                                }}
                            >
                                {subContent}
                            </Typography>

                            {hasButton && (
                                <Button
                                    variant='outlined'
                                    sx={{ borderRadius: '0' }}
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </>
    );
}

export default CompanyTextHeader;
