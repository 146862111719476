import { baseURL } from './baseUrl';

export const apiGetDesigns = async (
    mainCategory: string,
    applicationName: string,
    collectionName: string
) => {
    const response = await baseURL.get(
        `get-designs-by-cat-application-range?cat=${mainCategory}&application=${applicationName}&range=${collectionName}&start=0&count=10`
    );
    return response.data;
};

export const apiGetDesignsInCollection = async (filters: object) => {
    const response = await baseURL.post(`products/multi_filter`, filters, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
