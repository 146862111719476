// src/context/FilterContext.tsx

import React, { createContext, useState, ReactNode } from 'react';

interface FilterContextType {
    selectedApplications: string[];
    setSelectedApplications: (applications: string[]) => void;
}

export const FilterContext = createContext<FilterContextType>({
    selectedApplications: [],
    setSelectedApplications: () => {},
});

export const FilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedApplications, setSelectedApplications] = useState<string[]>([]);

    return (
        <FilterContext.Provider value={{ selectedApplications, setSelectedApplications }}>
            {children}
        </FilterContext.Provider>
    );
};
