import { Box, Divider, Typography } from '@mui/material';

interface ClearFiltersProps {
  clearFilters: () => void;
}

const ClearFilters = ({ clearFilters }: ClearFiltersProps) => {
  return (
    <Box>
      <Divider sx={{ backgroundColor: '#000000', m: '1rem 0' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          fontWeight='bold'
          mb={2}
          sx={{ fontSize: { xs: '20px', sm: '24px' } }}
        >
          Filters
        </Typography>
        <Typography
          fontWeight='bold'
          fontSize='20px'
          mb={2}
          sx={{
            cursor: 'pointer',
            fontSize: { xs: '20px', sm: '24px' },
          }}
          onClick={clearFilters}
        >
          Clear
        </Typography>
      </Box>
    </Box>
  );
};

export default ClearFilters;