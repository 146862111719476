import { Box, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';

const Pagination = ({
  totalPages,
  currentPage,
  previousPage,
  nextPage,
  productItemCount,
  lastPage,
  firstPage,
}: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', sm: 'row' },
        ml: 0,
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography mr={0}>
          {' '}
          Page {currentPage} of {isNaN(totalPages) ? '1' : totalPages}
        </Typography>

        <Box
          sx={{
            ml: 1,
          }}
        >
          <FirstPageIcon
            fontSize='small'
            sx={{
              cursor: 'pointer',
              ml: 2,
            }}
            onClick={() => {
              firstPage();
            }}
          />
          <ArrowBackIosIcon
            fontSize='small'
            sx={{
              cursor: 'pointer',
              ml: 2,
            }}
            onClick={() => {
              previousPage();
            }}
          />

          <ArrowForwardIosIcon
            fontSize='small'
            sx={{
              cursor: 'pointer',
              ml: 2,
            }}
            onClick={() => {
              nextPage();
            }}
          />
          <LastPageIcon
            fontSize='small'
            sx={{
              cursor: 'pointer',
              ml: 2,
            }}
            onClick={() => {
              lastPage();
            }}
          />
        </Box>
      </Box>
      <Typography pr={0}>
        Total: <b>{productItemCount}</b>
      </Typography>
    </Box>
  );
};

export default Pagination;
