import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { apiGetApplicationPageData } from "../../../api/apiApplications";
import CarouselBanner from "../banner/CarouselBanner";
import NewArrivalsGrid from "../grid/NewArrivalsGrid";
import FeaturedGallery from "../gallery/FeaturedGallery";
import MissingPage from "../errorMessages/MissingPage";
import CatalogueGrid from "../grid/CatalogueGrid";
import { catalogueData } from "../../../api/tempData";
import LoadingScreen from "../loading/LoadingScreen";
import TextHeader from "../../textHeader/TextHeader";
import CategoriesGrid from "../grid/CategoriesGrid";
import { useEffect } from "react";


function ApplicationTypeTemplate() {
  const { item_group, application_type } = useParams();

  const argumentDataStructure = {
    category: { application_type: application_type, item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>(
    [`application_type-${application_type}`, application_type],
    () => apiGetApplicationPageData(argumentDataStructure),
    { enabled: !!application_type }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <>
        <>
          {isLoading && <LoadingScreen />}
          {isError && <MissingPage />}
          {isSuccess && (
            <>
              <CarouselBanner
                heroImages={applicationData?.section_consumer_hero}
                heroVideo={applicationData?.section_consumer_hero_video ? {
                  video_url: applicationData.section_consumer_hero_video.video_url,
                  thumbnail_url: applicationData.section_consumer_hero_video.thumbnail_url
                } : undefined}
                heroLink={applicationData?.section_consumer_hero_link}
                videoLink={applicationData?.section_consumer_video_link}
              />

              <TextHeader
                heading={applicationData?.page_title}
                content={applicationData?.description}
                isCentered={false}
                width="70%"
                marginTop={10}
                marginBottom={5}
              />

            <CategoriesGrid
                data={applicationData.section_item_group_images.filter(
                  (item: { item_group: string }) => item?.item_group !== 'rugs' && item?.item_group !== 'homeware')}
                hasTitles={false}
                hasButtons={false}
                hasBackgroundText={true}
                spacingValue={6}
                hasCollectionLinks={false}
                marginTop={5}
                marginBottom={5}
                hasApplicationType={true}
              />

              <FeaturedGallery
                images={applicationData?.section_featured_collection}
                hasVideo={false}
                invert={false}
                marginTop={5}
                marginBottom={5}
                title={
                  applicationData?.featured_collection_title?.collection_name
                }
                description={applicationData?.featured_collection_description}
                itemGroup={
                  applicationData?.featured_collection_title?.item_group_code
                }
                application={
                  applicationData?.featured_collection_title?.application_name
                }
                collection={
                  applicationData?.featured_collection_title?.collection_name
                }
              />
              <NewArrivalsGrid
                header="New arrivals"
                data={applicationData?.section_new_arrivals}
                hasTitles={true}
                hasButtons={true}
                hasBackgroundText={false}
                spacingValue={4}
                marginTop={"5rem"}
                marginBottom={"5rem"}
              />
              <CatalogueGrid
                  header='View our latest catalogues'
                  catalogueData={catalogueData}
              />
            </>
          )}
        </>
    </>
  );
}

export default ApplicationTypeTemplate;