import { Box, Button, Card, CardActions, CardContent, Modal, Typography } from "@mui/material";
import { useState } from "react";

// Define TypeScript interfaces for better type safety
interface TradingHour {
    day: string;
    time: string;
}

interface StoreProps {
    title: string;
    holidayNotice?: string[]; // Optional array of strings
    address: string;
    phone: string;
    tradingHours: TradingHour[];
    mapUrl: string;
}

const CardsComponent: React.FC<StoreProps> = ({
                                                  title,
                                                  holidayNotice,
                                                  address,
                                                  phone,
                                                  tradingHours,
                                                  mapUrl
                                              }) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card
            elevation={0}
            sx={{
                p: '15px',
                borderRadius: '10px',
                width: { xs: '100%', lg: '447px' },
                height: '100%',
                border: '2px solid #f3f4f6',
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                mt: 2,
            }}>
            <CardContent sx={{ maxHeight: '100%' }}>
                {/* Store Title */}
                <Typography
                    sx={{
                        color: '#101828',
                        fontSize: '20px',
                        lineHeight: '1.2',
                        letterSpacing: '-0.48px',
                        fontWeight: '700'
                    }}>
                    {title}
                </Typography>

                {/* Conditionally Render Holiday Notice */}
                {holidayNotice && holidayNotice.length > 0 && (
                    <Box
                        sx={{
                            padding: '10px 0',
                            mt: 1,


                        }}
                    >
                        {holidayNotice.map((line, index) => (
                            <Typography
                                key={index}
                                sx={{
                                    fontSize: '14px',
                                    color: '#475467',
                                    fontWeight: "400",
                                    m: "5px 0"
                                }}
                            >
                                {line}
                            </Typography>
                        ))}
                    </Box>
                )}

                {/* Physical Address */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    mt: holidayNotice && holidayNotice.length > 0 ? 2 : 0,
                }}>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#475467',
                            fontWeight: '700',
                        }}
                    >
                        Physical Address
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: '14px', color: '#475467', fontWeight: "400", m: "10px 0" }}>
                        {address}
                    </Typography>
                </Box>

                {/* Telephone */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    mt: 2,
                }}>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#475467',
                            fontWeight: '700',
                        }}
                    >
                        Telephone
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: '14px', color: '#475467', fontWeight: "400", m: "10px 0" }}>
                        {phone}
                    </Typography>
                </Box>

                {/* Trading Hours */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    mt: 2,
                }}>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            color: '#475467',
                            fontWeight: '700',
                        }}
                    >
                        Trading Hours
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column', // Changed to column for better readability
                    alignItems: 'start',
                    justifyContent: 'start',
                    gap: '5px',
                    pb: '5px'
                }}>
                    {tradingHours.map((hours, index) => (
                        <Typography key={index} variant="caption" sx={{ fontSize: '14px', color: '#475467', fontWeight: "400" }}>
                            {hours.day}{hours.time ? `: ${hours.time}` : ''}
                        </Typography>
                    ))}
                </Box>
            </CardContent>

            {/* Card Actions: View Map Button */}
            <CardActions>
                <Button onClick={handleOpen} fullWidth sx={{ borderRadius: 0, py: '20px' }} variant="outlined">
                    View Map
                </Button>
                <Box>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                bgcolor: '#fff',
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                boxShadow: 24,
                                p: 2,
                                borderRadius: '10px',
                            }}>
                            <iframe
                                title={title}
                                src={mapUrl}
                                width="630"
                                height="573"
                                style={{ border: 0 }}
                                allowFullScreen
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Modal>
                </Box>
            </CardActions>
        </Card>
    )

}

export default CardsComponent
