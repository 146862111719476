import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

const ApplicationSelection = ({
                                availableApplications,
                                handleCheckboxChange,
                                applicationsList,
                                setApplicationsList,
                              }: any) => {
  return (
    <Box>
      <Divider sx={{ backgroundColor: '#000000', mb: '1rem' }} />
      <Typography fontWeight='bold' fontSize='20px' mb={2}>
        Applications
      </Typography>

      <Box sx={{ overflowY: 'auto', maxHeight: '20.5rem' }}>
        <FormGroup>
          {availableApplications
            ?.sort()
            .map((elem: string, index: number) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={applicationsList.includes(elem)}
                    onChange={handleCheckboxChange(
                      elem,
                      setApplicationsList,
                      applicationsList,
                      'application'
                    )}
                  />
                }
                label={elem}
              />
            ))}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default ApplicationSelection;