import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface StatusModalProps {
    statusOpen: boolean;
    setStatusOpen: (statusOpen: boolean) => void;
    icon: ReactNode;
    statusHeader: string;
    statusText: string;
    buttonText: string;
    link: string;
}
const StatusModal = ({
    statusOpen,
    setStatusOpen,
    icon,
    statusHeader,
    statusText,
    buttonText,
    link,
}: StatusModalProps) => {
    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            open={statusOpen}
            onClose={() => {
                setStatusOpen(false);
            }}
        >
            <Fade in={statusOpen}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: { xs: '90%', lg: 400 },
                    }}
                >
                    <Box
                        sx={{
                            width: '40px',
                            height: '40px',
                        }}
                    >
                        {icon}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontWeight: '600',
                                fontSize: '18px',
                            }}
                        >
                            {statusHeader}
                        </Typography>

                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '13px',
                            }}
                        >
                            {statusText}
                        </Typography>
                        <Button
                            disableElevation
                            variant='contained'
                            onClick={() => (window.location.href = `${link}`)}
                        >
                            {buttonText}
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default StatusModal;
