import { baseURL } from "./baseUrl";

export type ProductStatsModel = {
  data: {
    items_groups_stats?: string[];
    items?: {
      get_count: boolean;
      item_group: string;
      required_item_info: string[];
    };
  };
};

export type ProductCount = {
  products_count: number;
};

export type ProductStatResponseModel = {
  Fabric: ProductCount;
  Rugs: ProductCount;
  Wallpaper: ProductCount;
  homeware: {
    Bathroom: ProductCount;
    Bedroom: ProductCount;
    Curtains: ProductCount;
    Decorative: ProductCount;
    Furniture: ProductCount;
    Illumination: ProductCount;
    Scatters: ProductCount;
    Tableware: ProductCount;
    ThrowsQuiltsAndFurs: ProductCount;
    WallDecor: ProductCount;
  };
  brand?: string[];
  total_homeware_count: number;
  total_products_count: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const apiPostProductStats = async (
  data: ProductStatsModel
): Promise<ProductStatResponseModel> => {
  const response = await baseURL.post("products/stats", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data as ProductStatResponseModel;
};

export const apiGetProductData = async (
  mainCategory: string,
  productId: string
) => {
  const response = await baseURL.get(
    `get-product-in-cat-by-id?cat=${mainCategory}&id=${productId}`
  );
  return response.data;
};
export const apiGetProductVariations = async (
  mainCategory: string,
  applicationName: string,
  collectionName: string,
  designName: string
) => {
  const response = await baseURL.get(
    `get-products-list-by-design?cat=${mainCategory}&application=${applicationName}&range=${collectionName}&design=${designName}&start=0&count=5`
  );
  return response.data;
};
