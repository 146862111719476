import { baseURL } from './baseUrl';

export const apiGetHomePageData = async (data: object) => {
    const response = await baseURL.post(`get-all-cms-data`, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};
