import { useRef, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';
import { Grid, Box, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

function DocumentUploads({ setFileData }: any) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string>();

  const updateMutation = useMutation((data: string) => {
    return setFileData(data);
  });

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      let base64String = '';
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onloadend = () => {
        base64String = reader.result as string;
        setImageUploaded(true);
        setImageFile(base64String);

        updateMutation.mutate(base64String);
      };
    },
    [updateMutation]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    maxSize: 2097152,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    // @ts-ignore
    <li key={file.path}>
      {/* @ts-ignore */}
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    // @ts-ignore
    <li key={file.path}>
      {/* @ts-ignore */}
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <Box
        style={{
          marginTop: '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          sx={{
            border: '#98A2B3 1px solid',
            backgroundColor: '#F2F4F7',
            borderRadius: '10px',
            flex: '8',
            width: { xs: '100%', lg: '60%' },
            padding: {
              xs: '10px',
              sm: '20px',
            },
            cursor: 'pointer',
          }}
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          {...getRootProps()}
        >
          <Box
            style={{
              padding: '10px 12px',
              backgroundColor: '#98A2B3',
              borderRadius: '50%',
            }}
          >
            <CloudUploadOutlinedIcon />
            <input
              onClick={() => {
                fileInputRef.current?.click();
              }}
              type="file"
              ref={fileInputRef}
              accept="image/*,.pdf"
              {...getInputProps()}
            />
          </Box>

          <Typography color="var(--medium-dark-gray)" fontFamily={'Inter'}>
            <span
              style={{
                color: 'var(--secondary-color)',
                fontWeight: '600',
              }}
            >
              Click to upload
            </span>{' '}
            <br />
            <Typography
              sx={{
                display: { xs: 'none', lg: 'block' },
                color: 'var(--medium-dark-gray)',
                fontFamily: 'Inter',
              }}
            >
              or drag and drop
            </Typography>
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              color="var(--medium-dark-gray)"
              fontFamily={'Inter'}
              sx={{
                fontWeight: '400',
                fontSize: { xs: '12px', lg: '17px' },
              }}
            >
              PNG, JPG or PDF(Max 5mb)
            </Typography>
          </Box>

          {isDragActive && (
            <Typography
              color="var(--secondary-color)"
              style={{ fontWeight: 'bold' }}
            >
              Add your file
            </Typography>
          )}
          <Typography>{acceptedFileItems}</Typography>
          <Typography>{fileRejectionItems}</Typography>
        </Grid>
      </Box>
    </>
  );
}

export default DocumentUploads;
