// FilterComponent.tsx

import React, { useEffect, useState, useRef } from 'react';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ProductGrid from './components/ProductGrid';
import FilterSelection from './components/FilterSelection';
import ApplicationSelection from './components/ApplicationSelection';
import ClearFilters from './components/ClearFilters';
import { Grid, Box, Typography, Container, Button } from '@mui/material';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';


interface ApiResponse {
    filtered_colours?: { id: number }[];
    filtered_designs?: { id: number }[];
    filtered_types?: { id: number }[];
    filtered_brands?: { id: number }[];
    filtered_materials?: { id: number }[];
    filtered_standards?: { id: number }[];
    filtered_sizes?: string[];
    web_colours: { colours: string; idx: number }[];
    web_designs_style: { design_style: string; idx: number }[];
    webtypes: { types: string; idx: number }[];
    brands: { brand: string; idx: number }[];
    materials: { material: string; idx: number }[];
    standards: { standards: string; idx: number }[];
    web_sizes: string[];
    applications: string[];
    total_items_count: number;
}

// You can also add additional interfaces for the mutation
interface FilterMutationRequest {
    filter: {
        item_group: string;
    };
    off_setter: {
        start: number;
        end: number;
    };
    required_fields: {
        item_group: string;
        Applications: string[];
        WebTypes: number[];
        WebColors: number[];
        WebDesignStyles: number[];
        WebBrands: number[];
        WebMaterials: number[];
        WebStandards: number[];
        WebSizes: string[];
    };
}

const getDefaultApplications = (pathname: string): string[] => {
    const pathParts = pathname.split('/');
    const applicationIndex = pathParts.indexOf('application');

    if (applicationIndex === -1) return [];

    const applicationPath = decodeURIComponent(pathParts[applicationIndex + 1]);

    // Map of path values to their corresponding default applications
    const applicationDefaults: { [key: string]: string[] } = {
        'curtaining': ['Curtaining', 'Dual Purpose'],
        'dual purpose': ['Dual Purpose'],
        'upholstery': ['Upholstery', 'Dual Purpose']
    };

    return applicationDefaults[applicationPath.toLowerCase()] || [];
};

function FilterComponent({ itemGroup, title }: any) {
    const navigate = useNavigate();
    const location = useLocation();

    const [defaultApplications, setDefaultApplications] = useState<string[]>([]);

    // Application states
    const [availableApplications, setAvailableApplications] = useState<string[]>([]);
    const [applicationsList, setApplicationsList] = useState<string[]>([]);

// Available option states with proper types
    const [availableColours, setAvailableColours] = useState<{colours: string, idx: number}[]>([]);
    const [availableDesigns, setAvailableDesigns] = useState<{design_style: string, idx: number}[]>([]);
    const [availableTypes, setAvailableTypes] = useState<{types: string, idx: number}[]>([]);
    const [availableBrands, setAvailableBrands] = useState<{brand: string, idx: number}[]>([]);
    const [availableMaterials, setAvailableMaterials] = useState<{material: string, idx: number}[]>([]);
    const [availableStandards, setAvailableStandards] = useState<{standards: string, idx: number}[]>([]);
    const [availableSizes, setAvailableSizes] = useState<string[]>([]);

// Selected value states (these remain as string arrays)
    const [colourList, setColourList] = useState<string[]>([]);
    const [designList, setDesignList] = useState<string[]>([]);
    const [typesList, setTypesList] = useState<string[]>([]);
    const [brandList, setBrandList] = useState<string[]>([]);
    const [materialList, setMaterialList] = useState<string[]>([]);
    const [standardsList, setStandardsList] = useState<string[]>([]);
    const [sizeList, setSizeList] = useState<string[]>([]);

// Filtered options states
    const [filteredColours, setFilteredColours] = useState<{id: number}[]>([]);
    const [filteredDesigns, setFilteredDesigns] = useState<{id: number}[]>([]);
    const [filteredTypes, setFilteredTypes] = useState<{id: number}[]>([]);
    const [filteredBrands, setFilteredBrands] = useState<{id: number}[]>([]);
    const [filteredMaterials, setFilteredMaterials] = useState<{id: number}[]>([]);
    const [filteredStandards, setFilteredStandards] = useState<{id: number}[]>([]);
    const [filteredSizes, setFilteredSizes] = useState<string[]>([]);
    const [productItemCount, setProductItemCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 9;
    const [totalPages, setTotalPages] = useState<number>(0);

    const anchorRef = useRef<HTMLDivElement>(null);

    const getProductsMutation = useMutation<ApiResponse[], Error, FilterMutationRequest>(
      (filters) => apiFilterProducts(filters),
      {
          onSuccess: (data) => {
              const lastItem = data[data.length - 1] as ApiResponse;

              // Set application options
              setAvailableApplications(lastItem?.applications || []);

              // Set main available options with type enforcement
              setAvailableColours(lastItem?.web_colours || []);
              setAvailableDesigns(lastItem?.web_designs_style || []);
              setAvailableTypes(lastItem?.webtypes || []);
              setAvailableBrands(lastItem?.brands || []);

              // Set available options that need filtering
              setAvailableMaterials(
                lastItem?.materials?.filter(
                  (material) => material.material !== "-"
                ) || []
              );
              setAvailableStandards(
                lastItem?.standards?.filter(
                  (standard) => standard.standards !== "-"
                ) || []
              );

              // Process sizes with proper type handling
              const webSizes = Array.isArray(lastItem?.web_sizes) ? lastItem.web_sizes : [];
              const processedSizes = Array.from(
                new Set(
                  webSizes
                    .filter((size: unknown): size is string =>
                      typeof size === 'string' &&
                      size.trim() !== "-" &&
                      size.trim() !== ""
                    )
                    .map((size: string) =>
                      size
                        .trim()
                        .toLowerCase()
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                    )
                )
              );
              setAvailableSizes(processedSizes);

              // Handle filtered options based on application selection
              if (lastItem?.filtered_colours) {
                  setFilteredColours(lastItem.filtered_colours);
                  setFilteredDesigns(lastItem.filtered_designs || []);
                  setFilteredTypes(lastItem.filtered_types || []);
                  setFilteredBrands(lastItem.filtered_brands || []);
                  setFilteredMaterials(lastItem.filtered_materials || []);
                  setFilteredStandards(lastItem.filtered_standards || []);
                  setFilteredSizes(lastItem.filtered_sizes || processedSizes);
              } else {
                  // If no filtered data, use available options as filtered options
                  setFilteredColours(
                    lastItem?.web_colours?.map((c: { idx: number }) => ({ id: c.idx })) || []
                  );
                  setFilteredDesigns(
                    lastItem?.web_designs_style?.map((d: { idx: number }) => ({ id: d.idx })) || []
                  );
                  setFilteredTypes(
                    lastItem?.webtypes?.map((t: { idx: number }) => ({ id: t.idx })) || []
                  );
                  setFilteredBrands(
                    lastItem?.brands?.map((b: { idx: number }) => ({ id: b.idx })) || []
                  );
                  setFilteredMaterials(
                    lastItem?.materials?.filter((m) => m.material !== "-")
                      .map((m: { idx: number }) => ({ id: m.idx })) || []
                  );
                  setFilteredStandards(
                    lastItem?.standards?.filter((s) => s.standards !== "-")
                      .map((s: { idx: number }) => ({ id: s.idx })) || []
                  );
                  setFilteredSizes(processedSizes);
              }

              setProductItemCount(lastItem?.total_items_count || 0);
              setTotalPages(Math.ceil((lastItem?.total_items_count || 0) / itemsPerPage));
          },
          onError: (error: Error) => {
              console.error("Error fetching products:", error);
          }
      }
    );


    const handleCheckboxChange = (
      elem: string | number,
      setList: React.Dispatch<React.SetStateAction<string[]>>,
      list: string[],
      paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const elemStr = elem.toString();

        let updatedList: string[];
        if (isChecked) {
            updatedList = [...list, elemStr];
        } else {
            updatedList = list.filter((item) => item !== elemStr);
        }

        setList(updatedList); // This happens immediately

        // Return the event to maintain checkbox functionality
        return event;
    };





    const applyFilters = () => {
        const currentParams = new URLSearchParams();

        // Append all selected filters to the URL parameters
        applicationsList.forEach((app) => currentParams.append('application', app));
        colourList.forEach((col) => currentParams.append('colours', col));
        designList.forEach((design) => currentParams.append('design_style', design));
        typesList.forEach((type) => currentParams.append('types', type));
        brandList.forEach((brand) => currentParams.append('brand', brand));
        materialList.forEach((material) => currentParams.append('material', material));
        standardsList.forEach((std) => currentParams.append('standards', std));
        sizeList.forEach((sz) => currentParams.append('sizes', sz));

        // Reset pagination parameters
        currentParams.set('page', '1');
        currentParams.set('startoffset', '0');
        currentParams.set('endoffset', itemsPerPage.toString());

        // Update the URL with the new parameters
        navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });

        // Apply all filters at once
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: 0,
                end: itemsPerPage,
            },
            required_fields: {
                item_group: itemGroup,
                Applications: applicationsList,
                WebTypes: typesList.map(Number),
                WebColors: colourList.map(Number),
                WebDesignStyles: designList.map(Number),
                WebBrands: brandList.map(Number),
                WebMaterials: materialList.map(Number),
                WebStandards: standardsList.map(Number),
                WebSizes: sizeList.map(sz => sz.toLowerCase())
            },
        });

        // Update the current page state and scroll
        setCurrentPage(1);
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth',
            });
        }
    };



    const getInitialData = () => {
        const currentParams = new URLSearchParams(location.search);

        // Get all parameters from URL
        const applicationParams = currentParams.getAll('application');
        const colourParams = currentParams.getAll('colours');
        const designParams = currentParams.getAll('design_style');
        const typeParams = currentParams.getAll('types');
        const brandParams = currentParams.getAll('brand');
        const materialParams = currentParams.getAll('material');
        const standardsParams = currentParams.getAll('standards');
        const sizeParams = currentParams.getAll('sizes');

        // If no application params but we have defaults, use the defaults
        const applicationsToUse = applicationParams.length > 0
          ? applicationParams
          : defaultApplications;

        // Set states based on URL parameters or defaults
        setApplicationsList(applicationsToUse);
        setColourList(colourParams);
        setDesignList(designParams);
        setTypesList(typeParams);
        setBrandList(brandParams);
        setMaterialList(materialParams);
        setStandardsList(standardsParams);
        setSizeList(sizeParams.map(sz => sz.toLowerCase()));

        // Get pagination parameters
        const page = parseInt(currentParams.get('page') || '1');
        const startOffset = parseInt(currentParams.get('startoffset') || '0');
        const endOffset = parseInt(currentParams.get('endoffset') || itemsPerPage.toString());

        // Fetch data with all current filters
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: startOffset,
                end: endOffset,
            },
            required_fields: {
                item_group: itemGroup,
                Applications: applicationsToUse,
                WebTypes: typeParams.map(Number),
                WebColors: colourParams.map(Number),
                WebDesignStyles: designParams.map(Number),
                WebBrands: brandParams.map(Number),
                WebMaterials: materialParams.map(Number),
                WebStandards: standardsParams.map(Number),
                WebSizes: sizeParams.map(sz => sz.toLowerCase())
            },
        });

        setCurrentPage(page);
    };



    const clearFilters = () => {
        // Keep default applications, clear everything else
        setApplicationsList(defaultApplications);
        setColourList([]);
        setDesignList([]);
        setTypesList([]);
        setBrandList([]);
        setMaterialList([]);
        setStandardsList([]);
        setSizeList([]);


        // Update URL to only include default applications
        const params = new URLSearchParams();
        defaultApplications.forEach(app => params.append('application', app));
        navigate(`${location.pathname}${defaultApplications.length ? '?' + params.toString() : ''}`, { replace: true });

        // Reset pagination
        setCurrentPage(1);

        // Scroll to top
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65,
                behavior: 'smooth',
            });
        }

        // Re-fetch data with only default applications
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: 0,
                end: itemsPerPage,
            },
            required_fields: {
                item_group: itemGroup,
                Applications: defaultApplications,
                WebTypes: [],
                WebColors: [],
                WebDesignStyles: [],
                WebBrands: [],
                WebMaterials: [],
                WebStandards: [],
                WebSizes: [],

            },
        });
    };


    const updateOffsetParams = (params: { [key: string]: string }): void => {
        const currentParams = new URLSearchParams(location.search);

        Object.entries(params).forEach(([key, value]) => {
            currentParams.set(key, value);
        });

        navigate(`${location.pathname}?${currentParams.toString()}`, { replace: true });
    };

    // Scroll to the top of the FilterComponent
    const scrollToFilterComponent = () => {
        if (anchorRef.current) {
            window.scrollTo({
                top: anchorRef.current.offsetTop - 65, // Adjust the offset as needed
                behavior: 'smooth',
            });
        }
    };

    // Pagination functions adjusted to scroll to the top of FilterComponent
    const nextPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            const nextPageNum = currentPage + 1;
            const startOffset = (nextPageNum - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: nextPageNum.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(nextPageNum);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const previousPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            const prevPageNum = currentPage - 1;
            const startOffset = (prevPageNum - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: prevPageNum.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(prevPageNum);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const lastPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            const lastPageNumber = totalPages;
            const startOffset = (lastPageNumber - 1) * itemsPerPage;
            const endOffset = startOffset + itemsPerPage;

            updateOffsetParams({
                page: lastPageNumber.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(lastPageNumber);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    const firstPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            const firstPageNumber = 1;
            const startOffset = 0;
            const endOffset = itemsPerPage;

            updateOffsetParams({
                page: firstPageNumber.toString(),
                startoffset: startOffset.toString(),
                endoffset: endOffset.toString(),
            });

            setCurrentPage(firstPageNumber);

            // Scroll to the top of the FilterComponent
            scrollToFilterComponent();
        }
    };

    // Run getInitialData when the component mounts or when the URL parameters change
    useEffect(() => {
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]); // Listen for changes in URL parameters

    useEffect(() => {
        const defaults = getDefaultApplications(location.pathname);
        setDefaultApplications(defaults);

        // If this is initial load (no search params) and we have defaults, set them
        if (!location.search && defaults.length > 0) {
            setApplicationsList(defaults);

            // Update URL to include default applications
            const params = new URLSearchParams();
            defaults.forEach(app => params.append('application', app));
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });

            // Fetch products with default applications
            getProductsMutation.mutate({
                filter: {
                    item_group: itemGroup,
                },
                off_setter: {
                    start: 0,
                    end: itemsPerPage,
                },
                required_fields: {
                    item_group: itemGroup,
                    Applications: defaults,
                    WebTypes: [],
                    WebColors: [],
                    WebDesignStyles: [],
                    WebBrands: [],
                    WebMaterials: [],
                    WebStandards: [],
                    WebSizes: [],

                },
            });
        }
    }, [location.pathname]); //

    return (
      <Container maxWidth="xl">
          <Typography
            variant="h3"
            mb={1}
            mt={10}
            ml={2}
            fontWeight={400}
            fontSize={'40px'}
            textTransform={'capitalize'}
          >
              {`Discover - ${title}`}
          </Typography>
          <Typography fontWeight={400} fontSize="18px" mb={'-4rem'} ml={2}>
              Our inventory features thousands of designs. Use the selection bar below to find exactly what you’re looking for.
          </Typography>

          <Box ref={anchorRef}>
              <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    height: 'auto',
                    width: '100%',
                    padding: '1rem',
                    m: '4rem 0',
                }}
              >
                  <Box
                    sx={{
                        flex: { xs: 12, lg: 4 },
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: { xs: 0, lg: 5 },
                    }}
                  >
                      <ClearFilters clearFilters={clearFilters} />
                      {availableApplications.length > 1 && (
                        <ApplicationSelection
                          availableApplications={availableApplications}
                          handleCheckboxChange={handleCheckboxChange}
                          applicationsList={applicationsList}
                          setApplicationsList={setApplicationsList}
                        />
                      )}

                      <FilterSelection
                        // Existing props
                        availableColours={availableColours}
                        availableDesigns={availableDesigns}
                        availableTypes={availableTypes}
                        // Add filtered options
                        filteredColours={filteredColours}
                        filteredDesigns={filteredDesigns}
                        filteredTypes={filteredTypes}
                        filteredBrands={filteredBrands}
                        filteredMaterials={filteredMaterials}
                        filteredStandards={filteredStandards}
                        filteredSizes={filteredSizes}
                        // Rest of the existing props
                        handleCheckboxChange={handleCheckboxChange}
                        colourList={colourList}
                        designList={designList}
                        typesList={typesList}
                        setDesignList={setDesignList}
                        setColourList={setColourList}
                        setTypesList={setTypesList}
                        availableBrands={availableBrands}
                        brandList={brandList}
                        setBrandList={setBrandList}
                        availableMaterials={availableMaterials}
                        materialList={materialList}
                        setMaterialList={setMaterialList}
                        availableStandards={availableStandards}
                        standardsList={standardsList}
                        setStandardsList={setStandardsList}
                        availableSizes={availableSizes}
                        sizeList={sizeList}
                        setSizeList={setSizeList}
                        applicationsList={applicationsList}
                        anchorRef={anchorRef}
                        applyFilters={applyFilters}
                      />
                  </Box>
                  <Box
                    flex={{ xs: 12, lg: 8 }}
                    sx={{
                        height: '100%',
                        flexDirection: 'column',
                    }}
                  >
                      {getProductsMutation?.data?.length === 1 &&
                      (getProductsMutation?.data[0] as any)?.total_items_count === 0 ? (
                        <Box
                          sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              width: '100%',
                              height: '80vh',
                          }}
                        >
                            <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                No items found
                            </Typography>
                            <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                                Please try a different selection
                            </Typography>
                        </Box>
                      ) : (
                        <ProductGrid
                          productItemCount={productItemCount}
                          getProductsMutation={getProductsMutation}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          previousPage={previousPage}
                          nextPage={nextPage}
                          lastPage={lastPage}
                          firstPage={firstPage}
                          anchorRef={anchorRef}
                        />
                      )}
                  </Box>
              </Grid>
          </Box>
      </Container>
    );

}

export default FilterComponent;
