import { Box, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const ModalSection = ({
    title,
    resultsData,
    sectionName,
    keyName,
    showSection,
    setShowSection,
    itemCount,
    handleCloseModal,
}: any) => {
    const navigate = useNavigate();

    const handleNavigate = (elem: any) => {
        switch (sectionName) {
            case 'Category':
                navigate(`../item_group/${elem?.item_group}`);
                break;
            case 'Application':
                navigate(
                    `../item_group/${elem?.item_group}/application/${elem.application}`
                );
                break;
            case 'Brand':
                navigate(
                    `../item_group/${elem?.item_group}/brand/${elem.brand}`
                );
                break;
            case 'Collection':
                navigate(
                    `../item_group/${elem?.item_group}/application/${elem.application}/collection/${elem.range}`
                );
                break;
            case 'Design':
                navigate(
                    `../item_group/${elem?.item_group}/application/${elem.application}/collection/${elem.range}/design/${elem.design}`
                );
                break;
            case 'Colour':
                navigate(
                    `../item_group/${elem?.item_group}/application/${elem.application}/collection/${elem.range}/design/${elem.design}/colour/${elem.colour}`
                );
                break;
            case 'design_style':
                navigate(
                    `../item_group/${elem?.item_group}/application/${elem.application}/collection/${elem.range}/design/${elem.design}`
                );
                break;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography
                        variant='caption'
                        fontWeight={'500'}
                        sx={{
                            color: '#000',
                        }}
                    >
                        {title}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography
                        variant='caption'
                        sx={{ ml: '0.1rem', color: '#000' }}
                    >{`(${itemCount})`}</Typography>

                    {showSection ? (
                        <KeyboardArrowDown
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setShowSection(!showSection);
                            }}
                        />
                    ) : (
                        <KeyboardArrowUp
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                setShowSection(!showSection);
                            }}
                        />
                    )}
                </Box>
            </Box>

            {showSection && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mt: 1,
                        maxHeight: '8.5rem',
                        overflow: 'auto',
                    }}
                >
                    {resultsData?.results?.[sectionName]
                        .sort((a: any, b: any) =>
                            a?.[keyName]
                                .toLowerCase()
                                .localeCompare(b?.[keyName].toLowerCase())
                        )
                        .map((elem: any, index: number) => (
                            <Typography
                                variant='caption'
                                key={index}
                                sx={{ cursor: 'pointer', color: '#000' }}
                                onClick={() => {
                                    handleNavigate(elem);
                                    handleCloseModal();
                                }}
                            >
                                {sectionName === 'Collection'
                                    ? `${elem?.[keyName]} - ${elem.application}`
                                    : sectionName === 'Design'
                                    ? `${elem?.[keyName]} - ${elem.application} - ${elem.range}`
                                    : sectionName === 'Colour'
                                    ? `${elem?.[keyName]} - ${elem.design} - ${elem.group_desc}`
                                    : sectionName === 'design_style'
                                    ? `${elem?.[keyName]} - ${elem.design}`
                                    : `${elem?.[keyName]}`}
                            </Typography>
                        ))}
                </Box>
            )}
            <Divider sx={{ mt: 1 }} />
        </Box>
    );
};

export default ModalSection;
