import { Grid, Box, Typography } from '@mui/material';

function CompanyBanner({ imageData, hasVideo, video, hasTitle, title }: any) {
    return (
        <Grid sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%", // Note for devs: aspect ratio of 16:9 hack
          }}
          >
            {hasVideo ? (
                <video
                    style={{
                        width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            objectFit: "contain",
                    }}
                    src={video[0]?.src}
                    autoPlay
                    loop
                    muted
                />
            ) : (
                <img
                style={{
                    width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      objectFit: "contain",
                }}
                    src={imageData}
                    alt='hero'
                />
            )}

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
            >
                <Box
                    sx={{
                        zIndex: 999,
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    {hasTitle && (
                        <Typography fontWeight='100' variant='h1' sx={{
                            fontSize: {xs: '50px', lg: 'auto'}
                        }}>
                            {title}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Grid>
    );
}

export default CompanyBanner;
