import { useEffect, useState } from 'react';
import { Box, Typography, Breadcrumbs, Link, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useQuery } from 'react-query';
import { apiGetAllBrands } from '../../../../../api/apiBrands';
import { apiFilterProducts } from '../../../../../api/apiMultiFilter';

interface ImageMatch {
  image_data?: string;
  file_name?: string;
  brand_name?: string;
}

const ProductSection = ({
  productData,
  currentIndex,
  setCurrentIndex,
  itemGroup,
  application,
  collection,
  design,
  setCurrentSelectedItem,
  setSelectedImagePreviewIndex,
  selectedImagePreviewIndex,
}: any) => {
  const base = window.location.origin;

  const [size, setSize] = useState('');

  const [more, setMore] = useState(window.innerWidth > 600 ? 6 : 3);
  const [imageMatch, setImageMatch] = useState<ImageMatch>({});

  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const showMore = () => {
    setMore(productData?.length);
  };

  const showLess = () => {
    setMore(window.innerWidth > 600 ? 6 : 3);
  };

  useEffect(() => {
    setCurrentSelectedItem({
      image: productData[currentIndex]?.Product?.primaryImageUrl,
      name: productData[currentIndex]?.Product?.colour,
      quantity: 1,
      stock: productData[currentIndex]?.StockMaster?.on_hand,
      sku: productData[currentIndex]?.Product?.sku,

      description:
        productData[currentIndex]?.Product?.disambiguating_description,
      group: productData[currentIndex]?.StockMaster?.group_desc,
      cut_price: productData[currentIndex]?.StockMaster?.cut_price_inc_vat,
      roll_price: productData[currentIndex]?.StockMaster?.roll_price_inc_vat,
      retail_price:
        productData[currentIndex]?.StockMaster?.price_retail_inc_vat,
      id: productData[currentIndex]?.Product?.id,
      availableColourImages:
        productData[currentIndex]?.Product?.additionalImages === null
          ? [
              {
                image_data: productData[currentIndex]?.Product?.primaryImageUrl,
              },
            ]
          : productData[currentIndex]?.Product?.additionalImages,
    });
  }, [currentIndex, productData, setCurrentSelectedItem]);

  useQuery(
    ['brands', productData[currentIndex]?.StockMaster?.brand],
    async () => {
      const response = await apiGetAllBrands();
      if (response) {
        const imageMatch = response.find(
          (element: any) =>
            element.brand_name === productData[currentIndex]?.StockMaster?.brand
        );
        setImageMatch(imageMatch);
      }
      return response;
    },
    {
      initialData: [],
    }
  );

  const timeStamp = Number(
    productData[currentIndex]?.StockMaster?.po_delivery_date
  );
  const date = new Date(timeStamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const { data: sizeResults } = useQuery(
    ['size', itemGroup, application, design],
    () =>
      apiFilterProducts({
        filter: {
          item_group: itemGroup,
          application: application,
          design: design,
        },
        off_setter: { start: 0, end: 100 },
        focus_keys: ['web_size'],
      }),
    {
      enabled: !!itemGroup && !!application && !!design,
    }
  );

  useEffect(() => {
    if (
      sizeResults &&
      sizeResults.length > 0 &&
      sizeResults[sizeResults.length - 1]?.additional_info?.web_size
    ) {
      setSize('');
    }
  }, [sizeResults]);

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        pr: { xs: '0', lg: '50px' },
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          padding: {
            xs: '0 0',
            lg: '0 2rem',
          },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            color="inherit"
            href={`${base}/item_group/${itemGroup}`}
          >
            {productData[0]?.StockMaster?.group_desc}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`${base}/item_group/${itemGroup}/application/${application}`}
          >
            {application}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`${base}/item_group/${itemGroup}/application/${application}/collection/${collection}`}
          >
            {collection}
          </Link>
          <Typography color="text.primary">{design}</Typography>
        </Breadcrumbs>

        {/* Generic Details */}
        <Typography
          mt={2}
          variant="h5"
          fontSize={'40px'}
          fontWeight={'500'}
          lineHeight={'40px'}
        >
          {productData[0]?.StockMaster?.design}
        </Typography>
        <Typography fontWeight="600" lineHeight={'24px'} fontSize={'16px'}>
          {productData[currentIndex]?.Product?.colour}
        </Typography>
        {productData[currentIndex]?.Product?.long_description.length === '' ? (
          '0'
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              mt={2}
              fontSize={'18px'}
              fontWeight={'400'}
              lineHeight={'30px'}
            >
              {productData[currentIndex]?.Product?.long_description.length > 500
                ? showFullDescription
                  ? productData[currentIndex]?.Product?.long_description
                  : productData[
                      currentIndex
                    ]?.Product?.long_description.substring(0, 500) + '...'
                : productData[currentIndex]?.Product?.long_description}
              {productData[currentIndex]?.Product?.long_description.length >
                500 && (
                <Box>
                  <Button
                    size="small"
                    onClick={toggleDescription}
                    sx={{
                      textTransform: 'none',
                      cursor: 'pointer',
                      px: '0',
                    }}
                  >
                    {showFullDescription ? 'Less' : 'More'}
                  </Button>
                </Box>
              )}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            width: {
              xs: '8rem',
              sm: '8rem',
              md: '10rem',
              lg: '10rem',
            },
            height: 'auto',
          }}
          pt={'20px'}
        >
          <img
            src={imageMatch?.image_data}
            alt={imageMatch?.brand_name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>

        <Box
          mt={2}
          sx={{
            display: 'none',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {productData[currentIndex]?.StockMaster?.group_desc === 'Fabric' ? (
            <Typography fontWeight="bold" textTransform={'uppercase'}>
              Meters on Hand
            </Typography>
          ) : productData[currentIndex]?.StockMaster?.group_desc ===
            'Wallpaper' ? (
            <Typography fontWeight="bold" textTransform={'uppercase'}>
              Rolls on Hand
            </Typography>
          ) : (
            <Typography fontWeight="bold" textTransform={'uppercase'}>
              Units on Hand
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#2D2E2D',
                color: '#FFF',
                height: '26px',
              }}
            >
              <Box>
                <Typography
                  fontWeight="400"
                  textTransform={'uppercase'}
                  fontSize={'14px'}
                >
                  {parseInt(
                    productData[currentIndex]?.StockMaster?.on_hand
                  ).toFixed()}
                </Typography>
              </Box>
            </Button>
            <Button
              disableElevation
              variant="text"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#F2F4F7',
                color: '#000',
                height: '26px',
              }}
            >
              <Box>
                <Typography
                  fontWeight="400"
                  textTransform={'uppercase'}
                  fontSize={'14px'}
                >
                  View
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'none',
          }}
        >
          <Typography fontWeight="bold" textTransform={'uppercase'}>
            NEXT ETA {day}.{month}.{year}
          </Typography>
        </Box>
        {/* LIST */}
        <Box
          sx={{
            mt: '20px',

            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: 'auto',
              height: 'auto',
              cursor: 'all-scroll',
              overflowY: 'hidden',
              overflowX: productData?.length >= 6 ? 'auto' : 'hidden',
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
                border: '2px solid transparent',
                scrollbarWidth: 'thin',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#888',
                border: '2px solid transparent',
                scrollbarWidth: 'thin',
              },
              scrollbarWidth: 'thin',
              scrollbarColor: '#888 transparent',
            }}
          >
            {productData &&
              productData.length > 0 &&
              productData?.slice(0, more).map((product: any, index: number) => {
                if (size === '' || product?.StockMaster?.web_size === size) {
                  return (
                    <Box
                      sx={{
                        cursor: 'pointer',
                      }}
                      key={index}
                      onClick={() => {
                        setSelectedImagePreviewIndex(index);
                        setCurrentIndex(index);
                      }}
                    >
                      <img
                        style={{
                          height: '50px',
                          width: '50px',
                          padding: '5px',
                          marginRight: '0',
                          border:
                            selectedImagePreviewIndex === index
                              ? '1px solid black'
                              : 'none',
                        }}
                        src={product?.Product?.primaryImageUrl}
                        alt="primary"
                      />
                    </Box>
                  );
                }
                return null;
              })}{' '}
          </Box>
          {(window.innerWidth > 600 && productData.length > 6) ||
          (window.innerWidth <= 600 && productData.length > 3) ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {more === (window.innerWidth > 600 ? 6 : 3) ? (
                <Button
                  variant="contained"
                  onClick={showMore}
                  disableElevation
                  sx={{
                    borderRadius: '0px',
                    height: '50px',
                    width: '50px',
                    background: '#000',
                    color: '#FFF',
                  }}
                >
                  +{productData.length - more}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={showLess}
                  disableElevation
                  sx={{
                    borderRadius: '0px',
                    height: '50px',
                    width: '50px',
                    background: '#000',
                    color: '#FFF',
                    textTransform: 'capitalize',
                  }}
                >
                  Less
                </Button>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductSection;
