// Filter.tsx

import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type FilterTypeElement =
  | string
  | {
  idx: number | string;
  id?: string | number;  // Add id for filtered options
  [key: string]: any
};

interface FilterProps {
    filterType: FilterTypeElement[];
    handleCheckboxChange: (
        elem: string | number,
        setList: React.Dispatch<React.SetStateAction<string[]>>,
        list: string[],
        paramKey: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    list: string[];
    setList: React.Dispatch<React.SetStateAction<string[]>>;
    keyName: string;
    paramKey: string; // Add paramKey prop
}

const Filter = ({
                    filterType,
                    handleCheckboxChange,
                    list,
                    setList,
                    keyName,
                    paramKey,
                }: FilterProps) => {
    return (
        <Box
            sx={{
                overflowY: 'auto',
                maxHeight: '20.5rem',
            }}
        >
            <FormGroup>
                {filterType
                    .slice()
                    .sort((a, b) => {
                        const aValue = typeof a === 'string' ? a : a[keyName]?.toString() || '';
                        const bValue = typeof b === 'string' ? b : b[keyName]?.toString() || '';
                        return aValue.localeCompare(bValue);
                    })
                    .map((elem, index) => {
                        let key: string | number;
                        let label: string;
                        let value: string;

                        if (typeof elem === 'string') {
                            // Handle string elements (e.g., sizes)
                            key = `${elem}-${index}`; // Ensure a unique key
                            label = elem;
                            value = elem.toLowerCase(); // Normalize for consistency
                        } else {
                            // Handle object elements (e.g., types)
                            key = elem.idx; // `idx` is guaranteed to be defined
                            label = elem[keyName] ? elem[keyName] : 'Unknown';
                            value = elem.idx.toString();
                        }

                        const isChecked = list.includes(value);

                        return (
                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={handleCheckboxChange(
                                            value,
                                            setList,
                                            list,
                                            paramKey // Pass paramKey here
                                        )}
                                    />
                                }
                                label={label}
                            />
                        );
                    })}
            </FormGroup>
        </Box>
    );
};

export default Filter;
