import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import MissingPage from "../../../../../errorMessages/MissingPage";
import CollectionsGrid from "../../../../../grid/CollectionsGrid";
import LoadingScreen from "../../../../../loading/LoadingScreen";
import { apiGetApplicationPageData } from "../../../../../../../api/apiApplications";
import { useEffect } from "react";



const ApplicationTypeCategoryTemplate = () => {
  const { item_group, application_type, category_name } = useParams();

  let item_group_name;

  const argumentDataStructure = {
    category: { application_type: application_type, item_group: item_group },
    application_type_category : {category_name: category_name}
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: applicationData,
  } = useQuery<any, Error>(
    [`application_type-${application_type}`],
    () => apiGetApplicationPageData(argumentDataStructure),
    { enabled: !!application_type }
  );


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  switch (category_name) {
    case '005':
      item_group_name = 'Fabric';
      break;
    case '001':
      item_group_name = 'Wallpaper';
      break;
    default:
      item_group_name = undefined;
  }
  
  return (
      <>
        {isError && <MissingPage />}
        {!isLoading && isSuccess ? (
          <>
            <CollectionsGrid
                data={applicationData?.section_collections_in_application.filter(
                  (collection: { collection_name: string }, index: number, self: Array<{ collection_name: string }>) => 
                    self.findIndex(t => t.collection_name === collection.collection_name) === index
                )}
                applicationName={`${item_group_name} ${application_type && (application_type?.charAt(0)?.toUpperCase() + application_type?.slice(1) ?? '')}`}
                marginTop={5}
                marginBottom={5}
              />
          </>
        ) : <LoadingScreen />}
      </>
  );
};

export default ApplicationTypeCategoryTemplate;