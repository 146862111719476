import { useState, useEffect } from 'react';
import CompanyDetails from './CompanyDetails';
import Introduction from './Introduction';
import References from './References';
import Declaration from './Declaration';
import DocumentUpload from './DocumentUpload';
import PageLayout from './PageLayout';

import { Box, Container } from '@mui/material';

function RegistrationFormParent() {
  const [isShipping, setIsShipping] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0);

  const [formIsSubmitted, setFormIsSubmitted] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    company_details: [],
    trade_references: [],
    documentation: [],
    declaration: {},
  });

  const updateStep = () => {
    setStep((curr) => curr + 1);
  };

  const previousStep = () => {
    setStep((curr) => curr - 1);
  };

  const displayFormSteps = () => {
    switch (step) {
      case 0:
        return (
          <Introduction
            updateStep={updateStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
            setIsShipping={setIsShipping}
            isShipping={isShipping}
          />
        );
      case 1:
        return (
          <CompanyDetails
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 2:
        return (
          <References
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );
      case 3:
        return (
          <DocumentUpload
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
          />
        );

      case 4:
        return (
          <Declaration
            updateStep={updateStep}
            previousStep={previousStep}
            formData={formData}
            setFormData={setFormData}
            step={step}
            setFormIsSubmitted={setFormIsSubmitted}
          />
        );
    }
  };

  // ! Hit api once form is submitted boolean is true - not sure about this.
  // ! We will probably added a modal on the last step of the form and do the submission from there
  useEffect(() => {
    if (formIsSubmitted) {
    }
  }, [formIsSubmitted]);

  return (
    <PageLayout step={step}>
      <Container sx={{ mt: '5rem' }} maxWidth="lg">
        <Box>{displayFormSteps()}</Box>
      </Container>
    </PageLayout>
  );
}

export default RegistrationFormParent;
