import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    Container,
    CircularProgress,
} from '@mui/material';
import '../../../styles/App.css';
import { apiMailingListSignUp } from '../../../api/apiOmnisend';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

function MailingListSignup({ marginTop, marginBottom, isColor }: any) {
    const mutation: any = useMutation((body: any) => {
        return apiMailingListSignUp(body);
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>();

    const formSubmit = handleSubmit((data, e) => {
        e?.preventDefault();
        const dataStructure = {
            email: data.email,
        };


        mutation.mutate(dataStructure);
    });

    return (
        <Container
            maxWidth='xl'
            sx={{
                py: '96px',
            }}
        >
            <form onSubmit={formSubmit} noValidate>
                <Grid
                    sx={{
                        width: '100%',
                        backgroundColor: '#FCFCFD',
                        mt: '20px',
                        p: { xs: '5%', lg: '64px' },
                        borderRadius: '8px',
                        background: isColor ? '#F9FAFB' : '#FFF',
                    }}
                    container
                    justifyContent='space-between'
                    mt={marginTop}
                    mb={marginBottom}
                >
                    <Grid item xs={12} lg={6} pb={{ xs: '20px', lg: '0' }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                gap: '20px',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant='h4'
                                sx={{
                                    fontSize: '56px',
                                    fontWeight: '500',
                                    lineHeight: '44px',
                                    letterSpacing: '-1.12px',
                                }}
                            >
                                Receive wonderful news
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: '400',
                                    lineHeight: '30px',
                                    color: '#475467',
                                    width: { xs: '100%', lg: '75%' },
                                }}
                            >
                                Sign up for our newsletter and receive news
                                about our latest launches as well as updates on
                                trends and events – straight to your inbox.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    gap: '20px',
                                    justifyContent: 'flex-start',
                                    alignItems: {
                                        xs: 'flex-start',
                                    },
                                    mt: '20px',
                                }}
                            >
                                <TextField
                                    sx={{
                                        mr: '1rem',
                                        background: '#FFF',
                                        width: { xs: '100%', lg: '50%' },
                                    }}
                                    size='small'
                                    type='email'
                                    placeholder='Enter your email'
                                    {...register(`email`, {
                                        required: true,
                                        pattern:
                                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    })}
                                    error={errors.email ? true : false}
                                    helperText={
                                        errors.email
                                            ? `Email invalid! Email must include the
                                            @ character`
                                            : ''
                                    }
                                    inputProps={{
                                        sx: {
                                            paddingY: '13px',
                                            paddingX: '14px',
                                            fontFamily: 'Inter',
                                        },
                                    }}
                                />

                                <Button
                                    disableElevation
                                    sx={{
                                        borderRadius: 0,
                                        width: { xs: '100%', lg: '30%' },
                                        height: '100%',
                                        paddingY: '12px',
                                    }}
                                    variant='contained'
                                    type='submit'
                                >
                                    {' '}
                                    {mutation.isLoading ? (
                                        <CircularProgress
                                            sx={{
                                                color: '#FFF',
                                            }}
                                        />
                                    ) : (
                                        'Subscribe'
                                    )}
                                </Button>
                            </Box>
                            {mutation.isSuccess && (
                                <Typography mt={'1rem'} fontWeight={'bold'}>
                                    You have been subscribed
                                </Typography>
                            )}
                            {mutation.isError && (
                                <Typography mt={'1rem'} fontWeight={'bold'}>
                                    An error occured, please try again later
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default MailingListSignup;
