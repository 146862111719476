import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FilterProvider } from '../context/FilterContext';
import { ThemeStyles } from '../styles/Theme/ThemeStyles';
import { BrowserRouter } from 'react-router-dom';
import LoadingScreen from '../components/shared/loading/LoadingScreen';
import { ThemeProvider } from '@mui/material';

// Initialize React Query Client
const queryClient = new QueryClient();

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <ThemeProvider theme={ThemeStyles}>
                <FilterProvider>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            {children}
                        </BrowserRouter>
                    </QueryClientProvider>
                </FilterProvider>
            </ThemeProvider>
        </Suspense>
    );
};

export default AppProvider;
